"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Constants = void 0;
class Constants {
  constructor() {
    // misc.
    this.IDENTIFIER_STRING = 'STRING';
    this.IDENTIFIER_JSON = 'JSON';
    // based on https://gist.github.com/bugventure/f71337e3927c34132b9a
    // also https://github.com/mixer/uuid-validate/blob/master/index.js
    this.UUID_REGEX = '^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[1-4][a-fA-F0-9]{3}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$';
    this.MOBILE_REGEX = '^[6-9]{1}[0-9]{9}$';
    this.LINE_BREAK_REPLACE = '<<LBR>>';
    this.CUSTOM_ERROR_VALIDATION = 'CustomErrorValidation';
    this.GENERIC_ERROR = 'System errors occurred. Please try again later';
    this.INSUFFICIENT_CREDITS_ERROR = 'Not allowed as sufficient credits not available';
    // user types (IMPORTANT - must be in sync with db table user_types.type)
    this.STAFF_USER_TYPE = 'Staff';
    this.COLLEGES_USER_TYPE = 'College';
    this.STUDENTS_USER_TYPE = 'Student';
    this.EMPLOYERS_USER_TYPE = 'Employer';
    /* #####college roles and privileges##### */
    this.ROLE_COLL_SUPER_ADMIN = 'Super Administrator';
    // used to be Student Mentor earlier
    this.ROLE_COLL_STUDENT_MENTOR = 'Faculty';
    // used to be Department Coordinator earlier
    this.ROLE_COLL_DEPT_COORDINATOR = 'Head of Department';
    this.ROLE_COLL_PLACEMENT_STAFF = 'Placement Staff';
    this.ROLE_COLL_PRINCIPAL = 'Principal/Management';
    // college privileges (IMPORTANT - must be in sync with db table privileges.identifier)
    this.PRV_COLL_ALL_PRIVILEGES = 'PRV_COLL_ALL_PRIVILEGES';
    this.PRV_COLL_ADMIN_PRIVILEGES = 'PRV_COLL_ADMIN_PRIVILEGES';
    this.PRV_COLL_STUDENT_MENTOR = 'PRV_COLL_STUDENT_MENTOR';
    this.PRV_COLL_DEPT_COORDINATOR = 'PRV_COLL_DEPT_COORDINATOR';
    this.PRV_COLL_PRINCIPAL = 'PRV_COLL_PRINCIPAL';
    /* #####college roles and privileges##### */
    /* #####employer roles and privileges##### */
    // employer roles
    this.ROLE_EMPL_SUPER_ADMIN = 'Super Administrator';
    this.ROLE_EMPL_RECRUITER = 'Recruiter';
    // used to be HR Staff earlier
    this.ROLE_EMPL_HR_STAFF = 'Recruiting Manager';
    // employer privileges (IMPORTANT - must be in sync with db table privileges.identifier)
    this.PRV_EMPL_ALL_PRIVILEGES = 'PRV_EMPL_ALL_PRIVILEGES';
    this.PRV_EMPL_ADMIN_PRIVILEGES = 'PRV_EMPL_ADMIN_PRIVILEGES';
    this.PRV_EMPL_RECRUITER = 'PRV_EMPL_RECRUITER';
    /* #####employer roles and privileges##### */
    /* #####staff roles and privileges##### */
    // staff roles
    this.ROLE_STAFF_SUPER_ADMIN = 'Super Administrator';
    this.ROLE_STAFF_MANAGEMENT = 'Management User';
    this.ROLE_STAFF_BDMCOLLEGE = 'BDM - Colleges';
    this.ROLE_STAFF_BDMEMPLOYER = 'BDM - Employers';
    // combination of college and employer bdm privileges
    this.ROLE_STAFF_BDM = 'BDM';
    // staff privileges (IMPORTANT - must be in sync with db table privileges.identifier)
    this.PRV_STAFF_ALL_PRIVILEGES = 'PRV_STAFF_ALL_PRIVILEGES';
    this.PRV_STAFF_ADMIN_PRIVILEGES = 'PRV_STAFF_ADMIN_PRIVILEGES';
    this.PRV_STAFF_BDMCOLLEGE_PRIVILEGES = 'PRV_STAFF_BDMCOLLEGE_PRIVILEGES';
    this.PRV_STAFF_BDMEMPLOYER_PRIVILEGES = 'PRV_STAFF_BDMEMPLOYER_PRIVILEGES';
    /* #####staff roles and privileges##### */
    // missing or invalid
    this.MISSING_NAME = 'Name is missing';
    this.MISSING_INVALID_EMAIL = 'Email address is missing or invalid';
    this.MISSING_SUBJECT = 'Subject is missing';
    this.MISSING_MESSAGE = 'Message is missing';
    this.MISSING_ROLE = 'Role is missing';
    this.MISSING_INVALID_MOBILE = 'Mobile # is missing or invalid. A valid mobile # in India is a 10 digit number having the first digit as 6, 7, 8, 9. The 10 digit number can be prefixed by 0, 91, +91, 0091 (optional)';
    this.MISSING_PASSWORD = 'Password is missing';
    this.INVALID_PASSWORD_POLICY = 'Password must be at least 6 characters';
    this.MISSING_USER_TYPE = 'User type (Account type) is missing or invalid';
    this.MISSING_USER_ROLE = 'User role is missing or invalid';
    this.INVALID_USERACCT_EMAIL = 'Account already exists for email - %s';
    this.INVALID_USERACCT_MOBILE = 'Account already exists for mobile # - %s';
    this.INVALID_COLLEGEUSER_ACCOUNT = 'Account already exists for roll # - %s';
    this.INVALID_COLLEGE_CODE = "You have entered an invalid College Code. Please contact your college\u2019s Talent Placement Officer for the correct code";
    this.MISSING_CONFIRMATION_CODE = 'Confirmation code is missing';
    this.MISSING_EXISTING_PASSWORD = 'Existing Password is missing';
    this.MISSING_NEW_PASSWORD = 'New Password is missing';
    this.MISSING_PHONE = 'Phone # is missing';
    this.MISSING_CITY = 'City is missing';
    this.MISSING_JOB_TITLE = 'Job Title is missing';
    this.MISSING_COLLEGE_NAME = 'College Name is missing';
    this.MISSING_COLLEGE_CODE = 'College Code is missing';
    this.MISSING_COLLEGE_IDENTIFIER = 'College Identifier is missing or invalid (minimum 8 alphanumeric uppercase characters) required';
    this.MISSING_COLLEGE_ADDRESS = 'College Address is missing';
    this.MISSING_COLLEGE_TYPE = 'Institution Type is missing';
    this.MISSING_DEGREES_OFFERED = 'Courses offered details not available';
    this.MISSING_URL_SLUG = 'Url Slug is missing';
    this.INVALID_URL_SLUG = 'Url Slug is invalid';
    this.MISSING_ADMIN_IDENTIFIER = 'Admin identifier is missing';
    this.MISSING_STATE = 'State is missing';
    this.MISSING_COUNTRY = 'Country is missing';
    this.MISSING_ADDRESS_LINE1 = 'Address Line #1 is missing';
    this.MISSING_FILENAME = 'File name is missing';
    this.MISSING_CONTENTTYPE = 'Content type is missing';
    this.MISSING_FOLDERTYPE = 'Folder type is missing';
    this.INVALID_PDF_TYPE = 'Only PDF files are allowed';
    this.INVALID_CSV_TYPE = 'Only CSV files are allowed';
    this.INVALID_MP4_TYPE = 'Only MP4 files are allowed';
    this.MISSING_COLLEGE_DETAILS = 'College details is missing';
    this.MISSING_USER_IDENTIFIER = 'User identifier is missing';
    this.MISSING_BUSINESS_NAME = 'Business Name is missing';
    this.MISSING_BUSINESS_ADDRESS = 'Business Address is missing';
    this.MISSING_ADMIN_QUERY = 'Fetch Administrators - query is missing';
    this.MISSING_ADMIN_TYPE = 'Fetch Administrators - type is missing';
    this.MISSING_LINKCOLLEGE_TYPE = 'Link Colleges - type is missing';
    this.MISSING_DETAILED_PLANS = "Personality Assessment- \"detailed plans\" option is missing";
    this.MISSING_CLEAR_GOALS = "Personality Assessment- \"clear goals\" option is missing";
    this.MISSING_LIKE_EVERYONE = "Personality Assessment- \"like everyone\" option is missing";
    this.MISSING_FINISH_TASKS = "Personality Assessment- \"finish tasks\" option is missing";
    this.MISSING_MONEY_MOTIVATION = "Personality Assessment- \"money motivates\" option is missing";
    this.MISSING_TEAM_ENVIRONMENT = "Personality Assessment- \"team environment\" option is missing";
    this.MISSING_REALISTIC_CHOICE = "Interests Assessment- \"realistic\" option is missing";
    this.MISSING_INVESTIGATIVE_CHOICE = "Interests Assessment- \"investigative\" option is missing";
    this.MISSING_ARTISTIC_CHOICE = "Interests Assessment- \"artistic\" option is missing";
    this.MISSING_SOCIAL_CHOICE = "Interests Assessment- \"social\" option is missing";
    this.MISSING_ENTERPRISING_CHOICE = "Interests Assessment- \"enterprising\" option is missing";
    this.MISSING_CONVENTIONAL_CHOICE = "Interests Assessment- \"conventional\" option is missing";
    this.MISSING_FUN_CHOICE = "Values Assessment- \"fun\" option is missing";
    this.MISSING_JOBSECURITY_CHOICE = "Values Assessment- \"job security\" option is missing";
    this.MISSING_RECOGNITION_CHOICE = "Values Assessment- \"recognition\" option is missing";
    this.MISSING_COMPENSATION_CHOICE = "Values Assessment- \"compensation\" option is missing";
    this.MISSING_LOYALTY_CHOICE = "Values Assessment- \"loyalty\" option is missing";
    this.MISSING_STRUCTURE_CHOICE = "Values Assessment- \"structure\" option is missing";
    this.MISSING_INDEPENDENCE_CHOICE = "Values Assessment- \"independence\" option is missing";
    this.MISSING_RESPECT_CHOICE = "Values Assessment- \"respect\" option is missing";
    this.MISSING_RISK_CHOICE = "Values Assessment- \"risk taking\" option is missing";
    this.MISSING_VARIETY_CHOICE = "Values Assessment- \"variety\" option is missing";
    this.MISSING_LEARNING_CHOICE = "Values Assessment- \"learning\" option is missing";
    this.MISSING_TRAINING_CHOICE = "Values Assessment- \"training & development\" option is missing";
    this.MISSING_COLLEAGUE_CHOICE = "Values Assessment- \"colleague relationships\" option is missing";
    this.MISSING_DIFFERENCE_CHOICE = "Values Assessment- \"making difference\" option is missing";
    this.MISSING_CREATIVE_CHOICE = "Values Assessment- \"creative opportunities\" option is missing";
    this.MISSING_SKILL_NAME = 'Skill Name is missing';
    this.MISSING_SKILL_EXAMPLE = 'Skill Example is missing';
    this.MISSING_RANK = 'Rank is missing';
    this.MISSING_PLAN = 'Plan is missing';
    this.MISSING_JOB_TYPE = 'Job type is missing';
    this.MISSING_JOB_FUNCTION = 'Job function is missing';
    this.MISSING_LINK_TYPE = 'Post to colleges - link type is missing';
    this.MISSING_COLLEGES = 'Post to colleges - list is missing';
    this.MISSING_RESUME_URL = 'Please upload your resume';
    this.MISSING_JOB_LOCATION = 'Apply job - job location missing';
    this.MISSING_CANDIDATE_STATUS = 'Candidate status is missing';
    this.MISSING_CANDIDATE_NOTES = 'Candidate notes is missing';
    this.INVALID_GPA_MARKS = 'Invalid entry. Only numeric values are allowed. Please enter a whole number or a decimal number (up to 2 decimals)';
    this.MISSING_SEARCH_STATUS = 'Search status is missing';
    this.MISSING_RECOMMENDATION_LEVEL = 'Recommendation level is missing';
    this.MISSING_COLLEGE_DEGREEPROGRAM = 'College degree program is missing';
    this.MISSING_COLLEGE_DEGREECODE = 'College degree code is missing';
    this.MISSING_COLLEGE_DEGREEDURATION = 'College degree duration is missing';
    this.MISSING_COLLEGE_DEGREESPECN = 'College degree specialization is missing';
    this.MISSING_COLLEGE_ADMNYR = 'College admission year is missing';
    this.MISSING_COLLEGE_GRADYR = 'College graduation year is missing';
    this.INVALID_COLLEGE_ADMNGRADYR = 'College admission year or graduation year is invalid. Both of the values must be valid years and the difference should not be less than degree duration';
    this.MISSING_COLLEGE_ROLLNUMBER = 'College enrollment # is missing';
    this.ONEOF_EMAILMOBILE_EMAIL = 'Please specify your email address or your mobile #';
    this.ONEOF_EMAILMOBILE_MOBILE = 'Please specify your mobile # or your email address';
    this.MISSING_COLLEGE_ENROLLMENTYR = 'College enrollment year is missing';
    this.MISSING_INVALID_COLLEGEENMTDETAILS = 'College enrollment details is missing or invalid. Please ensure that you have entered details for all the years';
    this.MISSING_PLACEMENT_STATUS = 'Placement status is missing';
    this.MISSING_TERM = 'Term is missing';
    this.MISSING_TERM_START = 'Start month is missing or invalid';
    this.MISSING_TERM_END = 'End month is missing or invalid';
    this.MISSING_DESCRIPTION = 'Description is missing';
    this.MISSING_CHANGE_STATUS = 'Change status option is missing';
    this.MISSING_URL = 'Url is missing';
    this.MISSING_NOTE = 'Note is missing';
    this.MISSING_INVALID_RESUME = 'Resume is missing: Please upload a resume or build your resume using the Resume Builder';
    this.MISSING_EVENT_TITLE = 'Event title is missing';
    this.MISSING_EVENT_START = 'Event start time is missing';
    this.MISSING_EVENT_END = 'Event end time is missing';
    this.INVALID_DEACTIVATEACCOUNT_OPTIONS = 'Deactivate account - invalid options';
    this.MISSING_LEAD_STATUS = 'Lead status is missing';
    this.MISSING_LEAD_NOTES = 'Lead Notes is missing';
    this.MISSING_INVALID_CREDITS = 'Purchase Credits is missing or invalid. Please enter a positive number';
    this.MISSING_JOB_DEGREEPROGRAM = 'Degree program is missing. Atleast one program must be linked to the job';
    this.MISSING_JOB_ACADYR = 'Academic year is missing. Atleast one academic year must be linked to the job';
    this.INVALID_JOB_ACADYR = 'Please select atleast one academic year';
    this.MISSING_JOB_CTC = "CTC is missing. Please specify CTC as X per year / X per month where X refers to a numeric value. For example, 250000 - 300000 per year or 25000 per month";
    this.MISSING_INVALID_CTC = "CTC is missing or invalid. Please specify a minimum and maximum band (number) with an appropriate frequency";
    this.MISSING_INVALID_WEBSITE = 'Website URL is missing or invalid. Url must start with http:// or https://';
    this.INVALID_USER_CONTENT = 'Content appears to contain profanities that fall in the following categories: insult, sexual, inappropriate, discriminatory, blasphemy etc. Count of words in each category - %s';
    this.MISSING_DEGREE_IDENTIFIER = 'Degree Identifier is missing';
    this.MISSING_DEGREE_CODE = 'Degree Code is missing';
    this.MISSING_DEGREE_PROGRAM = 'Degree Program is missing';
    this.MISSING_INVALID_DGRDURATION = 'Degree Duration is missing or invalid. Must be zero or an integer value';
    this.MISSING_DEGREE_SPECIALIZATION = 'Degree Specialization is missing';
    this.INVALID_COLLDGR_IDENTIFIER = 'College Degree Identifier already exists for identifier - %s';
    this.MISSING_ACTION_TYPE = 'Action type is missing';
    this.MISSING_NEXT_URL = 'Next url is missing';
    this.MISSING_INVALID_RADIUSKMS = 'Kilometers not specified or invalid (must be a number)';
    this.MISSING_INVALID_RADIUSZC = 'Pincode not specified or invalid (must be a 6 digit number)';
    this.INVALID_EMPLSUBS_PREMIUMPLAN = 'Free Plan and Paid Plan are both enabled or disabled';
    this.INVALID_EMPLSUBS_GEOG = 'Pan-India Plan and States Plan are both enabled or disabled';
    this.INVALID_EMPLSUBS_DATES = 'Invalid start date and end date';
    this.INVALID_EMPLSUBS_AMOUNT = 'Invalid total amount or net amount';
    this.INVALID_EMPLSUBS_DISCOUNT = 'Invalid discount percentage';
    this.MISSING_OTP_CODE = 'OTP code is missing';
    this.MISSING_REQUEST_ID = 'Request ID is missing';
    // error
    this.REFRESH_TOKEN_ERROR = 'Tokens could not be generated';
    this.CONTACTUS_ERROR = 'Message could not be sent';
    this.SIGNUP_ERROR = 'Signup could not be completed';
    this.SIGNUP_CONFIRMATION_ERROR = 'Signup could not be confirmed';
    this.FORGOT_PASSWORD_ERROR = 'Forgot password request unsuccessful';
    this.CHANGE_PASSWORD_ERROR = 'Change password request unsuccessful';
    this.SIGNIN_GETCODE_ERROR = 'Signin - OTP could not be sent';
    this.SIGNIN_VERIFYCODE_ERROR = 'Signin - OTP could not be verified';
    this.SIGNIN_RESENDCODE_ERROR = 'Signin - OTP could not be sent';
    this.SIGNIN_ERROR = 'Signin could not be completed';
    this.SIGNIN_WAITING_ERROR = 'Your account is waiting for approval by %s at %s. You will receive an email when it is approved. You can only log into your %s account after the approval';
    this.SIGNIN_VERIFIED_ERROR = 'Your account has not been verified as yet. Please use the Forgot Password feature to activate your account and reset your password';
    this.SIGNIN_NOACCT_ERROR = 'No account found with %s';
    this.SIGNIN_NOACCTERR_INTERNAL = 'No account found - need to create new account';
    this.SIGNIN_PASSWORD_ERROR = 'Incorrect password entered. Enter the correct password and try again. If you forgot your password, please use the Forgot Password feature to reset';
    this.NO_MOBILEAPP_ERROR = 'Students can access the application only using the mobile app';
    this.SIGNOUT_ERROR = 'Signout could not be completed';
    this.GET_ACCOUNTPROFILE_ERROR = 'Account profile could not be retrieved';
    this.UPDATE_ACCOUNTPROFILE_ERROR = 'Account profile could not be updated';
    this.UPDATE_PASSWORD_ERROR = 'Update password request unsuccessful';
    this.FREEASSESSMENT_ERROR = 'Message could not be sent';
    this.COLLEGE_CONTACTUS_ERROR = 'Message could not be sent';
    this.URL_SLUG_ERROR = 'Url slug already in use';
    this.ADD_URLSLUG_ERROR = 'Add Url slug unsuccessful';
    this.UPD_URLSLUG_ERROR = 'Update Url slug unsuccessful';
    this.ADDCOLLEGE_URLSLUG_ERROR = 'Add College: Slug could not be created - %s. Please recheck if you have entered the slug in the proper format';
    this.ADDEMPLOYER_URLSLUG_ERROR = 'Add Employer: Slug could not be created - %s. Please recheck if you have entered the slug in the proper format';
    this.ADD_COLLEGE_ERROR = 'Add College unsuccessful. Please check if the administrator is already associated with another college';
    this.UPDATE_COLLEGE_ERROR = 'Update College unsuccessful';
    this.GET_S3SIGNEDURL_ERROR = 'S3 Signed Url could not be obtained';
    this.GET_COLLEGEDETAILS_ERROR = 'College details could not be obtained';
    this.GET_COLLEGESLIST_ERROR = 'Colleges list could not be obtained';
    this.APPROVE_COLLEGESLIST_ERROR = 'Colleges approval list could not be obtained';
    this.GET_STUDENTSLIST_ERROR = 'Students list could not be obtained';
    this.UPDATE_STUDENT_ERROR = 'Update Student details unsuccessful';
    this.DELETE_STUDENT_ERROR = 'Delete Student details unsuccessful';
    this.APPROVE_ALLSTUDENTS_ERROR = 'All students approval action unsuccessful';
    this.NOTIFY_PENDINGSTUDENTS_ERROR = 'Notify pending students action unsuccessful';
    this.NOTIFY_MESSAGESTUDENTS_ERROR = 'Message could not be sent to students';
    this.ASSIGN_MENTORSTUDENTS_ERROR = 'Mentor could not be assigned to student(s)';
    this.GET_EMPLOYERSLIST_ERROR = 'Employers list could not be obtained';
    this.ADD_EMPLOYER_ERROR = 'Add Employer unsuccessful. Please check if the administrator is already associated with another employer';
    this.UPDATE_EMPLOYER_ERROR = 'Update Employer unsuccessful';
    this.GET_EMPLOYERDETAILS_ERROR = 'Employer details could not be obtained';
    this.EMPLOYER_CONTACTUS_ERROR = 'Message could not be sent';
    this.GET_ADMINISTRATORS_ERROR = 'Administrators list could not be obtained';
    this.GET_EMPLOYERCOLLEGES_ERROR = 'Employer Colleges list could not be obtained';
    this.ADD_EMPLOYERCOLLEGE_ERROR = 'Employer College linking unsuccessful';
    this.GET_STUDENTASSESSMENTSLIST_ERROR = 'Student - Assessments list could not be obtained';
    this.GET_PERSONALITYASSESSMENT_ERROR = 'Personality Assessment test could not be obtained';
    this.ADD_PERSONALITYASSESSMENT_ERROR = 'Personality Assessment test could not be created';
    this.UPDATE_PERSONALITYASSESSMENT_ERROR = 'Personality Assessment test could not be updated';
    this.GET_INTERESTSASSESSMENT_ERROR = 'Interests Assessment test could not be obtained';
    this.ADD_INTERESTSASSESSMENT_ERROR = 'Interests Assessment test could not be created';
    this.UPDATE_INTERESTSASSESSMENT_ERROR = 'Interests Assessment test could not be updated';
    this.GET_VALUESASSESSMENT_ERROR = 'Values Assessment test could not be obtained';
    this.ADD_VALUESASSESSMENT_ERROR = 'Values Assessment test could not be created';
    this.UPDATE_VALUESASSESSMENT_ERROR = 'Values Assessment test could not be updated';
    this.GET_COMPETENCYASSESSMENT_ERROR = 'Competency Assessment test could not be obtained';
    this.ADD_COMPETENCYASSESSMENT_ERROR = 'Competency Assessment test could not be created';
    this.UPDATE_COMPETENCYASSESSMENT_ERROR = 'Competency Assessment test could not be updated';
    this.GET_EMPLOYERJOBS_ERROR = 'Employer jobs list could not be obtained';
    this.GET_EMPLOYERJOBDETAILS_ERROR = 'Employer job details could not be obtained';
    this.GET_EMPLJOBTMP_ERROR = 'Temporary employer job details could not be obtained';
    this.GET_DIRECTJOBDETAILS_ERROR = 'Direct job details could not be obtained';
    this.GET_DIRECTJOBTMP_ERROR = 'Temporary college direct job details could not be obtained';
    this.ADD_EMPLOYERJOB_ERROR = 'Employer job could not be created. Please check if you have administrator permissions';
    this.ADD_EMPLJOBSEC1_ERROR = 'Employer job: Part 1 could not be created';
    this.ADD_EMPLJOBSEC2_ERROR = 'Employer job: Part 2 could not be created';
    this.ADD_EMPLJOBSEC3_ERROR = 'Employer job: Part 3 could not be created';
    this.ADD_EMPLJOBSEC4_ERROR = 'Employer job: Part 4 could not be created'; // future
    this.ADD_EMPLJOBSEC5_ERROR = 'Employer job: Part 4 could not be created';
    this.ADD_EMPLJOBSEC6_ERROR = 'Employer job: Part 5 could not be created';
    this.APPR_EMPLJOB_ERROR = 'Employer job could not be approved';
    this.PAUSE_EMPLJOB_ERROR = 'Employer job could not be paused';
    this.REVT_EMPLJOB_ERROR = 'Employer job could not be reactivated';
    this.ADD_COLLEGEDIRECTJOB_ERROR = 'College direct job could not be created. Please check if you have administrator permissions';
    this.ADD_DIRECTJOBSEC1_ERROR = 'College direct job: Part 1 could not be created';
    this.ADD_DIRECTJOBSEC2_ERROR = 'College direct job: Part 2 could not be created';
    this.ADD_DIRECTJOBSEC3_ERROR = 'College direct job: Part 3 could not be created';
    this.ADD_DIRECTJOBSEC4_ERROR = 'College direct job: Part 4 could not be created'; // future
    this.ADD_DIRECTJOBSEC5_ERROR = 'College direct job: Part 4 could not be created';
    this.ADD_DIRECTJOBSEC6_ERROR = 'College direct job: Part 5 could not be created';
    this.APPR_DIRECTJOB_ERROR = 'College direct job could not be approved';
    this.PAUSE_DIRECTJOB_ERROR = 'College direct job could not be paused';
    this.REVT_DIRECTJOB_ERROR = 'College direct job could not be reactivated';
    this.UPDATE_EMPLOYERJOB_ERROR = 'Employer job could not be updated. Please check if you have administrator permissions';
    this.UPD_EMPLOYERJOB_ERROR = 'Employer job could not be created. Please check if you have administrator permissions';
    this.UPD_EMPLJOBSEC1_ERROR = 'Employer job: Part 1 could not be updated';
    this.UPD_EMPLJOBSEC2_ERROR = 'Employer job: Part 2 could not be updated';
    this.UPD_EMPLJOBSEC3_ERROR = 'Employer job: Part 3 could not be updated';
    this.UPD_EMPLJOBSEC4_ERROR = 'Employer job: Part 4 could not be updated'; // future
    this.UPD_EMPLJOBSEC5_ERROR = 'Employer job: Part 4 could not be updated';
    this.UPD_EMPLJOBSEC6_ERROR = 'Employer job: Part 5 could not be updated';
    this.UPDATE_COLLEGEDIRECTJOB_ERROR = 'College direct job could not be updated. Please check if you have administrator permissions';
    this.UPD_DIRECTJOBSEC1_ERROR = 'College direct job: Part 1 could not be updated';
    this.UPD_DIRECTJOBSEC2_ERROR = 'College direct job: Part 2 could not be updated';
    this.UPD_DIRECTJOBSEC3_ERROR = 'College direct job: Part 3 could not be updated';
    this.UPD_DIRECTJOBSEC4_ERROR = 'College direct job: Part 4 could not be updated'; // future
    this.UPD_DIRECTJOBSEC5_ERROR = 'College direct job: Part 4 could not be updated';
    this.UPD_DIRECTJOBSEC6_ERROR = 'College direct job: Part 5 could not be updated';
    this.DELETE_EMPLOYERJOB_ERROR = 'Employer job could not be deleted. Please check if you have administrator permissions';
    this.DELETE_EMPLJOBTMP_ERROR = 'Temporary employer job data could not be deleted. Please check if you have administrator permissions';
    this.DELETE_COLLEGEDIRECTJOB_ERROR = 'College direct job could not be deleted. Please check if you have administrator permissions';
    this.DELETE_DIRECTJOBTMP_ERROR = 'Temporary college direct job data could not be deleted. Please check if you have administrator permissions';
    this.GET_EMPLOYERLINKJOBS_ERROR = 'Employer link jobs list could not be obtained';
    this.EMPLOYER_LINKJOB_ERROR = 'Employer link job to college(s) unsuccessful';
    this.EMPLOYER_LINKJOB_STATUSERR = 'Employer link job to college(s) unsuccessful as the current status is not matching';
    this.GET_APPROVELINKEDJOBS_ERROR = 'Approval jobs list could not be obtained';
    this.GET_ALJ_RESUMES_ERROR = 'Resumes list could not be obtained';
    this.UPDATE_APPROVELINKEDJOBS_ERROR = 'Approval jobs - approval unsuccessful';
    this.SENDMSG_APPROVELINKEDJOBS_ERROR = 'Approval jobs - message could not be sent';
    this.UPLRES_APPROVELINKEDJOBS_ERROR = 'Upload resume unsuccessful';
    this.REMRES_APPROVELINKEDJOBS_ERROR = 'Delete resume unsuccessful';
    this.DELETE_APPROVELINKEDJOBS_ERROR = 'Approval jobs - rejection unsuccessful';
    this.ALJ_STUCOUNT_ERROR = 'Students count could not be obtained';
    this.ALJ_SMSCREDITS_ERROR = 'SMS Credits could not be obtained';
    this.ALJ_APPROVALNOTE_ERROR = 'Approval note could not be added/updated';
    this.GET_JOBDETAILS_ERROR = 'Job details could not be obtained';
    this.GET_STUDENTJOBS_ERROR = 'Student jobs list could not be obtained';
    this.GET_APPLYJOB_ERROR = 'Job application not allowed - possible reasons are (a)The job posting might have been paused or closed (b)You have already specified Secured Employment status (c)Your educational qualifications does not meet the job requirements';
    this.APPLY_STUDENTJOB_ERROR = 'Application for job unsuccessful';
    this.GET_VIEWCANDIDATES_ERROR = 'Candidates list could not be obtained';
    this.VIEWCANDIDATES_MSG_ERROR = 'Candidates list - message could not be sent';
    this.DEACTIVATEACCOUNT_ERROR_1 = 'Account could not be deactivated';
    this.DEACTIVATEACCOUNT_ERROR_2 = 'Account could not be reactivated';
    this.COLLEGE_APPROVAL_ERROR = 'College approval unsuccessful';
    this.COLLEGE_REJECT_ERROR = 'College rejection unsuccessful';
    this.RESUMEBLDR_GET_ERROR = 'Resume Builder - Get operation unsuccessful';
    this.RESUMEBLDR_ADD_ERROR = 'Resume Builder - Add operation unsuccessful';
    this.RESUMEBLDR_UPDATE_ERROR = 'Resume Builder - Update operation unsuccessful';
    this.STUDENTAPPTS_DETAILS_ERROR = 'Student Appointments - details could not be obtained';
    this.STUDENTAPPTS_MESSAGE_ERROR = 'Student Appointments - message could not be sent';
    this.STUDENTGPA_GET_ERROR = 'Student GPA - Get operation unsuccessful';
    this.STUDENTGPA_ADD_ERROR = 'Student GPA - Add operation unsuccessful';
    this.STUDENTGPA_UPDATE_ERROR = 'Student GPA - Update operation unsuccessful';
    this.STUDENTJOBSRCSTATUS_GET_ERROR = 'Student Job Search Status - Get operation unsuccessful';
    this.STUDENTJOBSRCHSTATUS_ADD_ERROR = 'Student Job Search Status - Add operation unsuccessful';
    this.STUDENTJOBSRCHSTATUS_UPDATE_ERROR = 'Student Job Search Status - Update operation unsuccessful';
    this.COLLEGESTNRECM_ADD_ERROR = 'Student Recommendation - Add operation unsuccessful';
    this.COLLEGESTNRECM_UPDATE_ERROR = 'Student Recommendation - Update operation unsuccessful';
    this.JOBREADINESS_GET_ERROR = 'Job Readiness - Get operation unsuccessful';
    this.JOBREADINESS_ADD_ERROR = 'Job Readiness - Add operation unsuccessful';
    this.JOBREADINESS_UPDATE_ERROR = 'Job Readiness - Update operation unsuccessful';
    this.VIEWRESUME_GET_ERROR = 'View Resume - Get operation unsuccessful';
    this.VIEWRESUME_UPD_ERROR = 'View Resume - Update operation unsuccessful';
    this.INVITE_STUDENTS_ERROR = 'College Invite Students operation not successful- please recheck if valid email address(es) are available';
    this.STUDENT_EMPLOYERSLIST_ERROR = 'Student employers list could not be obtained';
    this.INVALID_IMPORT_STUDENTS = 'CSV file is invalid. The first row of the file must be column header and one of the columns should be named EMAIL or MOBILE. Email address or Mobile # is a mandatory field. Please fix any column header issues in the file and upload it again.';
    this.IMPORT_STUDENTS_ERROR = 'College Import Students operation not successful- please recheck if the csv file is valid';
    this.GENERATE_REPORT_ERROR = 'Report output could not be obtained';
    this.GENERATE_FILTERS_ERROR = 'Filters output could not be obtained';
    this.GET_STUDENTDETAILS_ERROR = 'Student details could not be obtained';
    this.UPD_STUDENTDETAILS_ERROR = 'Student details could not be updated';
    this.GET_STUDENTSUMMARY_ERROR = 'Students list summary could not be obtained';
    this.INVITE_EMPLOYERS_ERROR = 'College Invite Employers operation not successful';
    this.UPDATE_STUDENTIDENTIFIER_ERROR = 'Student email address/mobile # could not be updated';
    this.SET_PASSWORD_ERROR = 'Set password request unsuccessful';
    this.STFNOTFSTD_GET_ERROR = 'Staff Notify students - get count operation unsuccessful';
    this.STFNOTFSTD_MSG_ERROR = 'Staff Notify students - send message operation unsuccessful';
    this.COLLEGEENMTDETAILS_GET_ERROR = 'College enrollment details - Get operation unsuccessful';
    this.COLLEGEENMTDETAILS_ADD_ERROR = 'College enrollment details - Add operation unsuccessful';
    this.COLLEGEENMTDETAILS_UPDATE_ERROR = 'College enrollment details - Update operation unsuccessful';
    this.GET_COLLEGEENMLIST_ERROR = 'Employer jobs list could not be obtained';
    this.STFNOTFTPO_GET_ERROR = 'Staff Notify College Users - get details operation unsuccessful';
    this.STFNOTFTPO_MSG_ERROR = 'Staff Notify College Users - send message operation unsuccessful';
    this.GET_COLLEGECOURSES_ERROR = 'College Courses list could not be obtained';
    this.TRACKACCUSR_APPRCOUNT_ERROR = 'Awaiting approval jobs count could not be obtained';
    this.TRACKACCUSR_COLLEGESLIST_ERROR = 'Colleges list could not be obtained';
    this.TRACKACCUSR_EMPLOYERSLIST_ERROR = 'Employers list could not be obtained';
    this.TRACKACCUSR_JOBSLIST_ERROR = 'Jobs list could not be obtained';
    this.TRACKACCUSR_SUMMARYDETAILS_ERROR = 'Summary details could not be obtained';
    this.TRACKACCUSR_COLLEGEDETAILS_ERROR = 'College details could not be obtained';
    this.TRACKACCUSR_EMPLOYERDETAILS_ERROR = 'Employer details could not be obtained';
    this.TRACKACCUSR_STUDENTDETAILS_ERROR = 'Student details could not be obtained';
    this.TRACKACCUSR_JOBDETAILS_ERROR = 'Job details could not be obtained';
    this.CANDIDATESTATUS_UPDATE_ERROR = 'Update Candidate status unsuccessful';
    this.CANDIDATENOTES_UPDATE_ERROR = 'Update Candidate notes unsuccessful';
    this.EMPLOYER_SIGNUP_ERROR = 'New employer account could not be created';
    this.PLACEMENT_STATUS_ERROR = 'Placement status could not be updated';
    this.GET_CCT_MAINLIST_ERROR = 'College Career Pathways list could not be obtained';
    this.GET_CCT_PROGRAMLIST_ERROR = 'College Career Pathways - program level list could not be obtained';
    this.GET_CCT_TERMLIST_ERROR = 'College Career Pathways - term level list could not be obtained';
    this.POST_CCT_PROGRAM_ERROR = 'College Career Pathways - linking to degree program not successful';
    this.POST_CCT_TERM_ERROR = 'College Career Pathways - linking to term(s) not successful';
    this.POST_CCT_TASK_ERROR = 'College Career Pathways - linking to task(s) not successful';
    this.PUT_CCT_PROGRAM_ERROR = 'College Career Pathways - update link to degree program not successful';
    this.PUT_CCT_TERM_ERROR = 'College Career Pathways - update link to term(s) not successful';
    this.PUT_CCT_TASK_ERROR = 'College Career Pathways - update link to task(s) not successful';
    this.PUT_CCT_DPLORDER_ERROR = 'College Career Pathways - update display order unsuccessful';
    this.DELETE_CCT_PROGRAM_ERROR = 'College Career Pathways - delete link to degree program not successful';
    this.DELETE_CCT_TERM_ERROR = 'College Career Pathways - delete link to term(s) not successful';
    this.DELETE_CCT_TASK_ERROR = 'College Career Pathways - delete link to task(s) not successful';
    this.CCT_UNIQUE_ERROR = 'Action could not be completed successfully - %s';
    this.GET_SCT_TASK_ERROR = 'Student Career Pathway - list could not be obtained';
    this.POST_SCT_TASK_ERROR = 'Student Career Pathway - task could not be completed';
    this.GET_CRRTUTL_ERROR = 'Career Timeline Tutorials list could not be obtained';
    this.POST_CRRTUTL_ERROR = 'Career Timeline Tutorials - new tutorial could not be added';
    this.PUT_CRRTUTL_ERROR = 'Career Timeline Tutorials - tutorial could not be updated';
    this.DELETE_CRRTUTL_ERROR = 'Career Timeline Tutorials - tutorial could not be deleted';
    this.GET_COLLSTAFF_ERROR = 'College Manage Staff - list could not be obtained';
    this.POST_COLLSTAFF_ERROR = 'College Manage Staff - new user could not be added';
    this.PUT_COLLSTAFF_ERROR = 'College Manage Staff - user could not be updated';
    this.DELETE_COLLSTAFF_ERROR = 'College Manage Staff - user could not be deleted';
    this.GET_MENTORNOTES_ERROR = 'Mentoring Notes - notes could not be obtained';
    this.POST_MENTORNOTES_ERROR = 'Mentoring Notes - note could not be added';
    this.PUT_MENTORNOTES_ERROR = 'Mentoring Notes - note could not be updated';
    this.DELETE_MENTORNOTES_ERROR = 'Mentoring Notes - note could not be deleted';
    this.GET_MULTIPLERECORDS_ERROR = 'Multiple records available - Please make use of email address / mobile # / college roll # to narrow the search';
    this.GET_EMPLSTAFF_ERROR = 'Employer Manage Staff - list could not be obtained';
    this.POST_EMPLSTAFF_ERROR = 'Employer Manage Staff - new user could not be added';
    this.PUT_EMPLSTAFF_ERROR = 'Employer Manage Staff - user could not be updated';
    this.DELETE_EMPLSTAFF_ERROR = 'Employer Manage Staff - user could not be deleted';
    this.DELETE_MEDIAFILE_ERROR = 'Media file could not be deleted';
    this.GET_JOBPLMTREPORT_ERROR = 'Job placement report could not be obtained';
    this.JOBPLMTREPORT_MSG_ERROR = 'Placement report - message could not be sent';
    this.GET_JOBPLMTSUMMARY_ERROR = 'Job placement summary could not be obtained';
    this.GET_EVTWKSHPLIST_ERROR = 'Events and Workshops - list could not be obtained';
    this.GET_EVTWKSHP_ERROR = 'Events and Workshops - details could not be obtained';
    this.POST_EVTWKSHP_ERROR = 'Events and Workshops - event could not be added';
    this.PUT_EVTWKSHP_ERROR = 'Events and Workshops - event could not be updated';
    this.DELETE_EVTWKSHP_ERROR = 'Events and Workshops - event could not be cancelled';
    this.UPDATE_EMPLCOLLEGE_ERROR = 'Employer College - update unsuccessful';
    this.GET_EMPLCANDSUMMARY_ERROR = 'Employer candidate summary could not be obtained';
    this.DL_EMPLCANDSUMMARY_ERROR = 'Resumes could not be obtained';
    this.GET_STAFFUSER_ERROR = 'Manage Staff - list could not be obtained';
    this.POST_STAFFUSER_ERROR = 'Manage Staff - new user could not be added';
    this.PUT_STAFFUSER_ERROR = 'Manage Staff - user could not be updated';
    this.DELETE_STAFFUSER_ERROR = 'Manage Staff - user could not be deleted';
    this.LEADSTATUS_UPDATE_ERROR = "Update Lead status unsuccessful. Please note that moving the college to Customer status or moving away from Customer status is not allowed";
    this.LEADNOTES_UPDATE_ERROR = 'Update Lead notes unsuccessful';
    this.STAFFCOLL_SINGLEEMAIL_ERROR = 'Message could not be sent';
    this.STAFFCOLL_BULKEMAIL_ERROR = 'Messages could not be sent';
    this.STAFFCOLL_BULKEMAILMAX_ERROR = 'Messages could not be sent as maximum limit exceeded. Please make use of Filters to reduce the # of records';
    this.EMPLCANDSUMMARY_LSU_ERROR = 'Link college status could not be updated';
    this.STURES_INAME_MISSING = 'Institution name is missing';
    this.STURES_DPROG_MISSING = 'Degree program is missing';
    this.STURES_DSPL_MISSING = 'Degree specialization is missing';
    this.STURES_AYR_MISSING = 'Admission year is missing';
    this.STURES_GYR_MISSING = 'Graduation year is missing';
    this.STURES_ROLLNO_MISSING = 'Roll # is missing';
    this.STURES_CURDGR_MISSING = 'Under education, Current degree / latest degree is missing';
    this.STURES_CURDGR_ERROR = 'Under education, you can only set one degree as Current degree / latest degree. Also, you cannot delete your current degree';
    this.STURES_USERDETVALN_ERROR = 'In your current / latest academic degree, some details like degree program, degree specialization, admission year, graduation year maybe missing or invalid. Both admission and graduation years must be specified as valid years and the difference should not be less than degree duration. Also, please check if the degree program and specialization is specified in the list of degrees offered by that college';
    this.STURES_ROLLNO_ERROR = 'Enrollment # already in use';
    this.STURES_DUPLCOLL_ERROR = 'User is already linked to this college with same degree program and specialization';
    this.GET_EMPLUSAGEREPORT_ERROR = 'Employer usage report could not be obtained';
    this.SECURED_EMPL_ERROR = 'You have already secured employment. Please see your Placement Office if you need access to the job board';
    this.PAUSED_EMPL_ERROR = 'This job posting has been paused. If the job posting is changed to Active status, you can then access it from the job board';
    this.GET_CREDITSUSAGEREPORT_ERROR = 'Credits usage report could not be obtained';
    this.ADD_CREDITS_RQSTERROR = 'Add Credits request unsuccessful';
    this.CFM_CLAIMCOLLEGE_ERROR = 'College claim unsuccessful';
    this.MAKEPAYMENT_RELCREDITS_ERROR = 'This feature is not available since the account is already on an unlimited plan';
    this.MAKEPAYMENT_OPTIONS_ERROR = 'Payment options could not be obtained. Please try again after some time';
    this.MAKEPMT_DISCCODE_ERROR = 'Discount code entered has expired or is invalid';
    this.MAKEPAYMENT_ERROR = 'Payment could not be processed. Please try again after some time';
    this.PHONEPEPAYMENT_ERROR = 'Payment url could not be generated. Please try again after some time';
    this.PHONEPEPAYMENT_CFM_ERROR = 'Payment transaction could not be confirmed. Please try again after some time';
    this.GET_TRACKINVOICES_ERROR = 'Invoices list could not be obtained';
    this.UPD_TRACKINVOICES_ERROR = 'Invoice details could not be updated';
    this.GET_COLLDGRDETAILS_ERROR = 'College degrees list could not be obtained';
    this.ADD_COLLDGRDETAIL_ERROR = 'College degree record could not be added';
    this.UPD_COLLDGRDETAIL_ERROR = 'College degree record could not be updated';
    this.GET_DIRECTLOGIN_ERROR = 'Get Direct login - operation could not be completed';
    this.UPD_DIRECTLOGIN_ERROR = 'Update Direct login - operation could not be completed';
    this.GET_APPLICANTCRDT_ERROR = 'Applicant credits list could not be obtained';
    this.GET_NOTNPREFERENCES_ERROR = 'Notification preferences could not be obtained';
    this.UPD_NOTNPREFERENCES_ERROR = 'Notification preferences could not be updated';
    this.STU_GRADYR_ERROR = 'Degree details like graduation year, admission year etc. are incomplete in your profile. Please update the relevant information in your degree(s) and try again';
    this.GET_EMPLSKILLS_ERROR = 'Employability skills list could not be obtained';
    this.POST_EMPLSKILLS_ERROR = 'Employability skills - completion could not be updated';
    this.PREMIUM_EMPLSKILLS_ERROR = 'Access to employability skills is allowed only for premium users';
    this.SYSASSIGN_UPD_ERROR = 'Allow system assignment flag could not be updated';
    this.ERROR_ECE_COLLLIST = 'List of colleges could not be obtained';
    this.ERROR_ECE_EVENTLIST = 'List of events could not be obtained';
    this.GET_CAREERPATHWAY_ERROR = 'Career Pathways list could not be obtained';
    this.POST_CAREERPATHWAY_ERROR = 'Career Pathway - completion could not be updated';
    this.GET_EMPLSUBSCRIPTION_ERROR = 'Employer subscription record could not be obtained';
    this.ADD_EMPLSUBSCRIPTION_ERROR = 'Employer subscription record could not be added';
    this.STUDB_EMAILUPD_ERROR = 'Email address could not be updated';
    this.SUPP_NAMEUPD_ERROR = 'Name could not be updated';
    this.SUPP_MOBILEUPD_ERROR = 'Mobile # could not be updated';
    this.SUPP_NOTNPREF_ERROR = 'Notification preferences could not be updated';
    this.SUPP_INACT_ERROR = 'Account could not be inactivated';
    this.VERIFY_CAPTCHA_ERROR = 'Captcha verification unsuccessful';
    this.URLSHORTENER_SLUG_ERROR = 'url shortener slug could not be generated';
    this.URLSHORTENER_REDIRECT_ERROR = 'url shortener redirect url could not be generated';
    this.GENERATE_PASSWORD_ERROR = 'password could not be generated';
    this.MISC_DETAILS_ERROR = 'Misc. details could not be obtained';
    this.VERIFY_CERT_ERROR = 'Certificate could not be verified';
    this.MDT_VALIDATION_ERROR = 'Mobile device token could not be linked to the user. Check if the user_id and token parameters are valid';
    this.MDT_GENERIC_ERROR = 'Mobile device token could not be linked to the user';
    this.FCMTEST_VALIDATION_ERROR = 'Notification could not be sent to the user. Check if the user_id and message parameters are valid';
    this.FCMTEST_GENERIC_ERROR = 'Notification could not be sent to the user';
    this.MDN_ACTIVATE_ERROR = 'Mobile device notifications could not be activated';
    this.MDN_INACTIVATE_ERROR = 'Mobile device notifications could not be inactivated';
    this.MDN_GETLIST_ERROR = 'Mobile device notifications list could not be obtained';
    this.MDN_GETDETAILS_ERROR = 'Mobile device notification details could not be obtained';
    this.MDN_GETCOUNT_ERROR = 'Mobile device notification count could not be obtained';
    this.MBPROXY_VALIDATION_ERROR = 'Unable to confirm user. Check if the token and match_uid parameters are valid';
    this.MBPROXY_GENERIC_ERROR = 'Unable to confirm user';
    this.MDN_DELN_ERROR = 'Mobile device notification could not be deleted';
    this.MDN_CHKOPN_ERROR = 'Mobile device notification status could not be updated';
    this.CLOSE_ACCOUNT_ERROR = 'Account could not be closed';
    // success
    this.CONTACTUS_SUCCESS = 'Message sent successfully';
    this.SIGNUP_SUCCESS = "An email has been sent to your email address.\n  ".concat(this.LINE_BREAK_REPLACE, "\n  Please check your Inbox/Junk folders. In some cases, the email might have been marked as spam.\n  ").concat(this.LINE_BREAK_REPLACE, "\n  Please click on the \"Confirm Your Email Address\" link in the email to confirm your account.");
    this.FORGOT_PASSWORD_SUCCESS = "An email has been sent to your email address with a password confirmation code.\n  ".concat(this.LINE_BREAK_REPLACE, "\n  Please check your Inbox/Spam folders. In some cases, the email might have been marked as spam.\n  ").concat(this.LINE_BREAK_REPLACE, "\n  Please note that the confirmation code will expire in 60 minutes.");
    this.FGTPWD_SUCCESS_MOBILE = "A text message has been sent to your mobile # with a password confirmation code.\n  ".concat(this.LINE_BREAK_REPLACE, "\n  Please note that the confirmation code will expire in 60 minutes.");
    this.SIGNUPCFM_NOUSERTYPE_SUCCESS = 'Signup confirmation not required for this user';
    this.SIGNUPCFM_CLAIMED_SUCCESS = 'Signup already confirmed for this user';
    this.SIGNUP_CONFIRMATION_SUCCESS = 'Signup confirmed successfully';
    this.CHANGE_PASSWORD_SUCCESS = 'Change password request successful';
    this.SIGNOUT_SUCCESS = 'Signout successfully completed';
    this.UPDATE_ACCOUNTPROFILE_SUCCESS = 'Account profile updated successfully';
    this.UPDATE_PASSWORD_SUCCESS = 'Update password request successful';
    this.FREEASSESSMENT_SUCCESS = 'Message sent successfully';
    this.COLLEGE_CONTACTUS_SUCCESS = 'Message sent successfully';
    this.URL_SLUG_SUCCESS = 'Url slug available';
    this.ADD_COLLEGE_SUCCESS = 'Add College successful';
    this.UPDATE_COLLEGE_SUCCESS = 'Update College successful';
    this.UPDATE_STUDENT_SUCCESS = 'Update Student details successful';
    this.DELETE_STUDENT_SUCCESS = 'Delete Student details successful';
    this.APPROVE_ALLSTUDENTS_SUCCESS = 'All students approval action successful';
    this.NOTIFY_PENDINGSTUDENTS_SUCCESS = 'Notify pending students action successful';
    this.NOTIFY_MESSAGESTUDENTS_SUCCESS = 'Message sent to students successfully';
    this.ASSIGN_MENTORSTUDENTS_SUCCESS = 'Mentor assigned to student(s) successfully';
    this.ADD_EMPLOYER_SUCCESS = 'Add Employer successful';
    this.UPDATE_EMPLOYER_SUCCESS = 'Update Employer successful';
    this.EMPLOYER_CONTACTUS_SUCCESS = 'Message sent successfully';
    this.ADD_EMPLOYERCOLLEGE_SUCCESS = 'Employer College linking successful';
    this.ADD_PERSONALITYASSESSMENT_SUCCESS = 'Personality Assessment test created successfully';
    this.UPDATE_PERSONALITYASSESSMENT_SUCCESS = 'Personality Assessment test updated successfully';
    this.ADD_INTERESTSASSESSMENT_SUCCESS = 'Interests Assessment test created successfully';
    this.UPDATE_INTERESTSASSESSMENT_SUCCESS = 'Interests Assessment test updated successfully';
    this.ADD_VALUESASSESSMENT_SUCCESS = 'Values Assessment test created successfully';
    this.UPDATE_VALUESASSESSMENT_SUCCESS = 'Values Assessment test updated successfully';
    this.ADD_COMPETENCYASSESSMENT_SUCCESS = 'Competency Assessment test created successfully';
    this.UPDATE_COMPETENCYASSESSMENT_SUCCESS = 'Competency Assessment test updated successfully';
    this.ADD_EMPLOYERJOB_SUCCESS = 'Employer job created successfully';
    this.ADD_EMPLJOBSEC1_SUCCESS = 'Employer job - Part 1 created successfully';
    this.ADD_EMPLJOBSEC2_SUCCESS = 'Employer job - Part 2 created successfully';
    this.ADD_EMPLJOBSEC3_SUCCESS = 'Employer job - Part 3 created successfully';
    this.ADD_EMPLJOBSEC4_SUCCESS = 'Employer job - Part 4 created successfully'; // future
    this.ADD_EMPLJOBSEC5_SUCCESS = 'Employer job - Part 4 created successfully';
    this.ADD_EMPLJOBSEC6_SUCCESS = 'Employer job - Part 5 created successfully';
    this.APPR_EMPLJOB_SUCCESS = 'Employer job approved successfully';
    this.PAUSE_EMPLJOB_SUCCESS = 'Employer job paused successfully';
    this.REVT_EMPLJOB_SUCCESS = 'Employer job reactivated successfully';
    this.UPDATE_EMPLOYERJOB_SUCCESS = 'Employer job updated successfully';
    this.UPD_EMPLJOBSEC1_SUCCESS = 'Employer job - Part 1 updated successfully';
    this.UPD_EMPLJOBSEC2_SUCCESS = 'Employer job - Part 2 updated successfully';
    this.UPD_EMPLJOBSEC3_SUCCESS = 'Employer job - Part 3 updated successfully';
    this.UPD_EMPLJOBSEC4_SUCCESS = 'Employer job - Part 4 updated successfully'; // future
    this.UPD_EMPLJOBSEC5_SUCCESS = 'Employer job - Part 4 updated successfully';
    this.UPD_EMPLJOBSEC6_SUCCESS = 'Employer job - Part 5 updated successfully';
    this.DELETE_EMPLOYERJOB_SUCCESS = 'Employer job deleted successfully';
    this.DELETE_EMPLJOBTMP_SUCCESS = 'Temporary employer job data deleted successfully';
    this.ADD_COLLEGEDIRECTJOB_SUCCESS = 'College direct job created successfully';
    this.ADD_DIRECTJOBSEC1_SUCCESS = 'College direct job - Part 1 created successfully';
    this.ADD_DIRECTJOBSEC2_SUCCESS = 'College direct job - Part 2 created successfully';
    this.ADD_DIRECTJOBSEC3_SUCCESS = 'College direct job - Part 3 created successfully';
    this.ADD_DIRECTJOBSEC4_SUCCESS = 'College direct job - Part 4 created successfully'; // future
    this.ADD_DIRECTJOBSEC5_SUCCESS = 'College direct job - Part 4 created successfully';
    this.ADD_DIRECTJOBSEC6_SUCCESS = 'College direct job - Part 5 created successfully';
    this.APPR_DIRECTJOB_SUCCESS = 'College direct job approved successfully';
    this.PAUSE_DIRECTJOB_SUCCESS = 'College direct job paused successfully';
    this.REVT_DIRECTJOB_SUCCESS = 'College direct job reactivated successfully';
    this.UPDATE_COLLEGEDIRECTJOB_SUCCESS = 'College direct job updated successfully';
    this.UPD_DIRECTJOBSEC1_SUCCESS = 'College direct job - Part 1 updated successfully';
    this.UPD_DIRECTJOBSEC2_SUCCESS = 'College direct job - Part 2 updated successfully';
    this.UPD_DIRECTJOBSEC3_SUCCESS = 'College direct job - Part 3 updated successfully';
    this.UPD_DIRECTJOBSEC4_SUCCESS = 'College direct job - Part 4 updated successfully'; // future
    this.UPD_DIRECTJOBSEC5_SUCCESS = 'College direct job - Part 4 updated successfully';
    this.UPD_DIRECTJOBSEC6_SUCCESS = 'College direct job - Part 5 updated successfully';
    this.DELETE_COLLEGEDIRECTJOB_SUCCESS = 'College direct job deleted successfully';
    this.DELETE_DIRECTJOBTMP_SUCCESS = 'Temporary college direct ob data deleted successfully';
    this.EMPLOYER_LINKJOB_SUCCESS = 'Employer link job to college(s) successful';
    this.EMPLOYER_LINKJOB_SUCCESSALT = '%s requires approval. We have sent an email to the Placement Officer informing of your interest in recruiting from their college. You will be notified when the college approves the request.';
    this.UPDATE_APPROVELINKEDJOBS_SUCCESS = 'Approval jobs - approval successful';
    this.SENDMSG_APPROVELINKEDJOBS_SUCCESS = 'Message sent successfully';
    this.UPLRES_APPROVELINKEDJOBS_SUCCESS = 'Resume uploaded successfully';
    this.REMLRES_APPROVELINKEDJOBS_SUCCESS = 'Resume deleted successfully';
    this.DELETE_APPROVELINKEDJOBS_SUCCESS = 'Approval jobs - rejection successful';
    this.GET_APPLYJOB_NORESUME = 'Resume builder - resume not available';
    this.APPLY_STUDENTJOB_SUCCESS = 'Application for job successful';
    this.DEACTIVATEACCOUNT_SUCCESS_1 = 'Account deactivated successfully';
    this.DEACTIVATEACCOUNT_SUCCESS_2 = 'Account reactivated successfully';
    this.COLLEGE_APPROVAL_SUCCESS = 'College approval successful';
    this.COLLEGE_REJECT_SUCCESS = 'College rejection successful';
    this.RESUMEBLDR_ADD_SUCCESS = 'Resume Builder - Add operation successful';
    this.RESUMEBLDR_UPDATE_SUCCESS = 'Resume Builder - Update operation successful';
    this.STUDENTAPPTS_MESSAGE_SUCCESS = 'Student Appointments - message sent successfully';
    this.STUDENTGPA_ADD_SUCCESS = 'Student GPA - Add operation successful';
    this.STUDENTGPA_UPDATE_SUCCESS = 'Student GPA - Update operation successful';
    this.STUDENTGPA_APPROVAL_SUCCESS = 'Student GPA - Approval operation successful';
    this.STUDENTJOBSRCHSTATUS_ADD_SUCCESS = 'Student Job Search Status - Add operation successful';
    this.STUDENTJOBSRCHSTATUS_UPDATE_SUCCESS = 'Student Job Search Status - Update operation successful';
    this.COLLEGESTNRECM_ADD_SUCCESS = 'Student Recommendation - Add operation successful';
    this.COLLEGESTNRECM_UPDATE_SUCCESS = 'Student Recommendation - Update operation successful';
    this.JOBREADINESS_ADD_SUCCESS = 'Job Readiness - Add operation successful';
    this.JOBREADINESS_UPDATE_SUCCESS = 'Job Readiness - Update operation successful';
    this.INVITE_STUDENTS_SUCCESS = 'Successfully sent invitations';
    this.INVITE_EMPLOYERS_SUCCESS = 'Successfully sent invitation';
    this.IMPORT_STUDENTS_SUCCESS = 'Import under process. You will receive an email shortly';
    this.DLDRESUME_NOFILES_SUCCESS = 'No files available for download';
    this.UPDATE_STUDENTEMAIL_SUCCESS = 'Student email address updated successfully';
    this.STFNOTFSTD_MSG_SUCCESS = 'Message has been sent to all the selected college students';
    this.COLLEGEENMTDETAILS_ADD_SUCCESS = 'College enrollment details - Add operation successful';
    this.COLLEGEENMTDETAILS_UPDATE_SUCCESS = 'College enrollment details - Update operation successful';
    this.STFNOTFTPO_MSG_SUCCESS = 'Message has been sent to all the selected college staff';
    this.VIEWCANDIDATES_MSG_SUCCESS = 'Candidates list - message sent successfully';
    this.GET_EMPLOYERLINKJOBS_SUCCESS = 'Please narrow your search by entering City and/or College Name';
    this.CANDIDATESTATUS_UPDATE_SUCCESS = 'Update Candidate status successful';
    this.CANDIDATENOTES_UPDATE_SUCCESS = 'Update Candidate notes successful';
    this.EMPLOYER_SIGNUP_SUCCESS = 'New employer account created successfully';
    this.PLACEMENT_STATUS_SUCCESS = 'Placement status updated successfully';
    this.POST_CCT_PROGRAM_SUCCESS = 'College Career Pathways - linking to degree program successful';
    this.POST_CCT_TERM_SUCCESS = 'College Career Pathways - linking to term(s) successful';
    this.POST_CCT_TASK_SUCCESS = 'College Career Pathways - linking to task(s) successful';
    this.PUT_CCT_PROGRAM_SUCCESS = 'College Career Pathways - update link to degree program successful';
    this.PUT_CCT_TERM_SUCCESS = 'College Career Pathways - update link to term(s) successful';
    this.PUT_CCT_TASK_SUCCESS = 'College Career Pathways - update link to task(s) successful';
    this.PUT_CCT_DPLORDER_SUCCESS = 'College Career Pathways - update display order successful';
    this.DELETE_CCT_PROGRAM_SUCCESS = 'College Career Pathways - delete link to degree program successful';
    this.DELETE_CCT_TERM_SUCCESS = 'College Career Pathways - delete link to term(s) successful';
    this.DELETE_CCT_TASK_SUCCESS = 'College Career Pathways - delete link to task(s) successful';
    this.POST_SCT_TASK_SUCCESS = 'Student Career Pathway - task completed successfully';
    this.POST_CRRTUTL_SUCCESS = 'Career Timeline Tutorials - new tutorial added successfully';
    this.PUT_CRRTUTL_SUCCESS = 'Career Timeline Tutorials - tutorial updated successfully';
    this.DELETE_CRRTUTL_SUCCESS = 'Career Timeline Tutorials - tutorial deleted successfully';
    this.POST_COLLSTAFF_SUCCESS = 'College Manage Staff - new user added successfully';
    this.PUT_COLLSTAFF_SUCCESS = 'College Manage Staff - user updated successfully';
    this.DELETE_COLLSTAFF_SUCCESS = 'College Manage Staff - user deleted successfully';
    this.POST_MENTORNOTES_SUCCESS = 'Mentoring Notes - note added successfully';
    this.PUT_MENTORNOTES_SUCCESS = 'Mentoring Notes - note updated successfully';
    this.DELETE_MENTORNOTES_SUCCESS = 'Mentoring Notes - note deleted successfully';
    this.POST_EMPLSTAFF_SUCCESS = 'Employer Manage Staff - new user added successfully';
    this.PUT_EMPLSTAFF_SUCCESS = 'Employer Manage Staff - user updated successfully';
    this.DELETE_EMPLSTAFF_SUCCESS = 'Employer Manage Staff - user deleted successfully';
    this.DELETE_MEDIAFILE_SUCCESS = 'Media file deleted successfully';
    this.JOBPLMTREPORT_MSG_SUCCESS = 'Placement report - message sent successfully';
    this.POST_EVTWKSHP_SUCCESS = 'Events and Workshops - event added successfully';
    this.PUT_EVTWKSHP_SUCCESS = 'Events and Workshops - event updated successfully';
    this.DELETE_EVTWKSHP_SUCCESS = 'Events and Workshops - event cancelled successfully';
    this.UPDATE_EMPLCOLLEGE_SUCCESS = 'Employer College - update successful';
    this.CLAIMCOLLEGE_SUCCESS = 'Thank you for your interest in claiming and managing your %s site.  One of our Customer Success Managers will be reaching out to you soon to provide with the information to access your account.';
    this.POST_STAFFUSER_SUCCESS = 'Manage Staff - new user added successfully';
    this.PUT_STAFFUSER_SUCCESS = 'Manage Staff - user updated successfully';
    this.DELETE_STAFFUSER_SUCCESS = 'Manage Staff - user deleted successfully';
    this.LEADSTATUS_UPDATE_SUCCESS = 'Update Lead status successful';
    this.LEADNOTES_UPDATE_SUCCESS = 'Update Lead notes successful';
    this.STAFFCOLL_SINGLEEMAIL_SUCCESS = 'Message sent successfully';
    this.STAFFCOLL_BULKEMAIL_SUCCESS = 'Messages will be sent out shortly. Please check Status and Notes for the colleges after some time';
    this.EMPLCANDSUMMARY_LSU_SUCCESS = 'Link college status updated successfully';
    this.ADD_CREDITS_RQSTSUCCESS = 'Add Credits request successful';
    this.ADD_CREDITS_SUCCESS = 'Add Credits successful';
    this.CFM_CLAIMCOLLEGE_SUCCESS = 'College claimed successfully';
    this.MAKEPAYMENT_SUCCESS = 'Payment processed successfully';
    this.UPD_TRACKINVOICES_SUCCESS = 'Invoice details updated successfully';
    this.INACT_COLLEGE_SUCCESS = 'College inactivation successful';
    this.INACT_EMPLOYER_SUCCESS = 'Employer inactivation successful';
    this.APPLYJOB_NONEXTERNAL = 'Student can apply for non-external job';
    this.APPLYJOB_EXTERNAL = 'Student can apply for external job';
    this.ADD_COLLDGRDETAIL_SUCCESS = 'College degree record added successfully';
    this.UPD_COLLDGRDETAIL_SUCCESS = 'College degree record updated successfully';
    this.UPD_URLSLUG_SUCCESS = 'Update Url slug successful';
    this.UPD_NOTNPREFERENCES_SUCCESS = 'Notification preferences updated successfully';
    this.POST_EMPLSKILLS_SUCCESS = 'Employability skills - completion updated successfully';
    this.SYSASSIGN_UPD_OK = 'Allow system assignment flag updated successfully';
    this.POST_CAREERPATHWAY_SUCCESS = 'Career Pathways - completion updated successfully';
    this.VIEWRESUME_UPD_SUCCESS = 'View Resume - Update operation successful';
    this.ADD_EMPLSUBSCRIPTION_SUCCESS = 'Employer subscription record added successfully';
    this.STUDB_EMAILUPD_SUCCESS = 'Email address updated successfully';
    this.ALJ_APPROVALNOTE_SUCCESS = 'Approval note added/updated successfully';
    this.SUPP_NAMEUPD_SUCCESS = 'Name updated successfully';
    this.SUPP_MOBILEUPD_SUCCESS = 'Mobile # updated successfully';
    this.SUPP_NOTNPREF_SUCCESS = 'Notification preferences updated successfully';
    this.SUPP_INACT_SUCCESS = 'Account inactivated successfully';
    this.VERIFY_CAPTCHA_SUCCESS = 'Captcha verified successfully';
    this.PHONEPEPAYMENT_CFM_PENDING = 'Payment transaction confirmation is pending';
    this.PHONEPEPAYMENT_CFM_SUCCESS = 'Payment transaction confirmed successfully';
    this.MDT_GENERIC_SUCCESS = 'Mobile device token linked to the user successfully';
    this.FCMTEST_SUCCESS = 'Notification sent to the user successfully';
    this.MDN_ACTIVATE_SUCCESS = 'Mobile device notifications activated successfully';
    this.MDN_INACTIVATE_SUCCESS = 'Mobile device notifications inactivated successfully';
    this.MBPROXY_USER_SUCCESS = 'User confirmed successfully';
    this.MDN_DELN_SUCCESS = 'Mobile device notification deleted successfully';
    this.MDN_CHKOPN_SUCCESS = 'Mobile device notification status updated successfully';
    this.CLOSE_ACCOUNT_SUCCESS = 'Account closed successfully';
    // identifiers
    this.IDENTIFIER_CONTACTUS = 'contactUs';
    this.IDENTIFIER_CHANGEPASSWORD = 'changePassword';
    this.IDENTIFIER_COLLEGECONTACTUS = 'collegeContactUs';
    this.IDENTIFIER_COLLEGEDASHBOARD = 'collegeDashboard';
    this.IDENTIFIER_COLLEGEDETAILS = 'collegeDetails';
    this.IDENTIFIER_COLLEGESTUDENTS = 'collegeStudents';
    this.IDENTIFIER_COLLEGESTUDENTS_MESSAGE = 'collegeStudentsMessage';
    this.IDENTIFIER_ACCOUNTPROFILE = 'accountProfile';
    this.IDENTIFIER_EMPLOYERDETAILS = 'employerDetails';
    this.IDENTIFIER_FORGOTPASSWORD = 'forgotPassword';
    this.IDENTIFIER_S3SIGNEDURL = 's3SignedUrl';
    this.IDENTIFIER_SIGNIN = 'signin';
    this.IDENTIFIER_SIGNOUT = 'signout';
    this.IDENTIFIER_SIGNUP = 'signup';
    this.IDENTIFIER_SIGNUPCONFIRMATION = 'signupConfirmation';
    this.IDENTIFIER_STAFFCOLLEGES = 'staffColleges';
    this.IDENTIFIER_STAFFEMPLOYERS = 'staffEmployers';
    this.IDENTIFIER_UPDATEPASSWORD = 'updatePassword';
    this.IDENTIFIER_URLSLUG = 'urlSlug';
    this.IDENTIFIER_EMPLOYERCONTACTUS = 'employerContactUs';
    this.IDENTIFIER_FETCHADMINISTRATORS = 'fetchAdministrators';
    this.IDENTIFIER_EMPLOYERLINKCOLLEGES = 'employerLinkColleges';
    this.IDENTIFIER_EMPLOYERCOLLEGESLIST = 'employerCollegesList';
    this.IDENTIFIER_STUDENTASSESSMENTSLIST = 'studentAssessmentsList';
    this.IDENTIFIER_STUDENTPERSONALITYASSESSMENT = 'studentPersonalityAssessment';
    this.IDENTIFIER_STUDENTINTERESTSASSESSMENT = 'studentInterestsAssessment';
    this.IDENTIFIER_STUDENTVALUESASSESSMENT = 'studentValuesAssessment';
    this.IDENTIFIER_STUDENTCOMPETENCYASSESSMENT = 'studentCompetencyAssessment';
    this.IDENTIFIER_EMPLOYERJOBS = 'employerJobs';
    this.IDENTIFIER_EMPLOYERJOBSLIST = 'employerJobsList';
    this.IDENTIFIER_EMPLOYERLINKJOBS = 'employerLinkJobs';
    this.IDENTIFIER_APPROVELINKEDJOBS = 'approveLinkedJobs';
    this.IDENTIFIER_JOBDETAILS = 'jobDetails';
    this.IDENTIFIER_STUDENTJOBSLIST = 'studentJobsList';
    this.IDENTIFIER_EMPLOYERDASHBOARD = 'employerDashboard';
    this.IDENTIFIER_DEACTIVATEACCOUNT = 'deactivateAccount';
    this.IDENTIFIER_COLLEGEAPPROVAL = 'collegeApproval';
    this.IDENTIFIER_STUDENTRESUMEBUILDER = 'studentResumeBuilder';
    this.IDENTIFIER_STUDENTAPPOINTMENTS = 'studentAppointments';
    this.IDENTIFIER_STUDENTJOBSEARCHSTATUS = 'studentJobSearchStatus';
    this.IDENTIFIER_COLLEGESTNRECM = 'collegeStudentRecommendation';
    this.IDENTIFIER_COLLEGEJOBREADINESS = 'collegeJobReadiness';
    this.IDENTIFIER_VIEWRESUME = 'viewResume';
    this.IDENTIFIER_STUDENTEMPLLIST = 'studentEmployersList';
    this.IDENTIFIER_COLLEGEIMPORTSTUDENTS = 'collegeImportStudents';
    this.IDENTIFIER_COLLEGEDIRECTJOBS = 'collegeDirectJobs';
    this.IDENTIFIER_STAFFRESENDINVITE = 'staffResendInvite';
    this.IDENTIFIER_STUDENTDASHBOARD = 'studentDashboard';
    this.IDENTIFIER_COLLSTUSUMMARY = 'collegeStudentsSummary';
    this.IDENTIFIER_COLLEGEINVITEEMPLOYERS = 'collegeInviteEmployers';
    this.IDENTIFIER_SUPPORTISSUES = 'supportIssues';
    this.IDENTIFIER_STAFF_NOTIFYSTUDENTS = 'staffNotifyStudents';
    this.IDENTIFIER_CONTACT_CAREERSERVICES = 'careerContactServices';
    this.IDENTIFIER_COMPONENTMOUNT = 'componentMount';
    this.IDENTIFIER_PERSONALITYASSESSMENT = 'personalityAssessment';
    this.IDENTIFIER_INTERESTSASSESSMENT = 'interestsAssessment';
    this.IDENTIFIER_VALUESASSESSMENT = 'valuesAssessment';
    this.IDENTIFIER_COMPETENCYASSESSMENT = 'competencyAssessment';
    this.IDENTIFIER_STAFFDASHBOARD = 'staffDashboard';
    this.IDENTIFIER_STAFF_NOTIFYTPOS = 'staffNotifyTpos';
    this.IDENTIFIER_COLLEGEENROLLMENTDETAILS = 'collegeEnrollmentDetails';
    this.IDENTIFIER_COLLEGEENROLLMENTLIST = 'collegeEnrollmentList';
    this.IDENTIFIER_INCOMPLETEPROFILE_MESSAGE = 'studentIncompleteProfile';
    this.IDENTIFIER_EMPLOYERSIGNUP = 'employerSignup';
    this.IDENTIFIER_COLLEGECOURSES = 'collegeCourses';
    this.IDENTIFIER_TRACKACCESSUSERS = 'trackAccessUsers';
    this.IDENTIFIER_VIEWCANDIDATES = 'viewCandidates';
    this.IDENTIFIER_CANDIDATESTATUS = 'candidateStatus';
    this.IDENTIFIER_CANDIDATENOTES = 'candidateNotes';
    this.IDENTIFIER_COLLEGE_CAREERTL = 'collegeCareerTimelines';
    this.IDENTIFIER_STUDENT_CAREERTL = 'studentCareerTimeline';
    this.IDENTIFIER_CAREERTL_TUTORIALS = 'careerTimelineTutorials';
    this.IDENTIFIER_COLL_MANAGE_STAFF = 'collegeManageStaff';
    this.IDENTIFIER_MENTORING_NOTES = 'mentoringNotes';
    this.IDENTIFIER_EMPL_MANAGE_STAFF = 'employerManageStaff';
    this.IDENTIFIER_JOBPLMTREPORT = 'jobPlacementReport';
    this.IDENTIFIER_JOBPLMTSUMMARY = 'jobPlacementSummary';
    this.IDENTIFIER_EVENTS_WORKSHOPS = 'eventsWorkshops';
    this.IDENTIFIER_EMPLCANDSUMMARY = 'employerCandidateSummary';
    this.IDENTIFIER_APPLYJOB = 'applyJob';
    this.IDENTIFIER_CLAIMCOLLEGE = 'claimCollege';
    this.IDENTIFIER_STAFF_MANAGE_USERS = 'staffManageUsers';
    this.IDENTIFIER_ABOUTUS = 'aboutUs';
    this.IDENTIFIER_COLLEGE_GETSTARTED = 'collegeGetStarted';
    this.IDENTIFIER_STUDENT_GETSTARTED = 'studentGetStarted';
    this.IDENTIFIER_EMPLOYER_GETSTARTED = 'employerGetStarted';
    this.IDENTIFIER_TOS = 'termsOfService';
    this.IDENTIFIER_PRIVPOLICY = 'privacyPolicy';
    this.IDENTIFIER_STU_CAREERCLIST = 'studentCareerCheckList';
    this.IDENTIFIER_STU_HOWTO = 'studentHowTo';
    this.IDENTIFIER_STU_INTERNSHIPS = 'studentInternships';
    this.IDENTIFIER_STU_EVTWORKSHOPS = 'studentWorkshops';
    this.IDENTIFIER_COLLEGE_WIDGETS = 'collegeWidgets';
    this.IDENTIFIER_STURES_JOBSEARCH = 'studentResourcesJobSearch';
    this.IDENTIFIER_STURES_HIREDQUICKLY = 'studentResourcesHiredQuickly';
    this.IDENTIFIER_STURES_MAINPARTS = 'studentResourcesMainParts';
    this.IDENTIFIER_STURES_DOSDONTS = 'studentResourcesDosDonts';
    this.IDENTIFIER_STURES_COVERLETTER = 'studentResourcesCoverLetter';
    this.IDENTIFIER_STURES_DRESSCODE = 'studentResourcesDressCode';
    this.IDENTIFIER_STURES_TENTIPS = 'studentResourcesTenTips';
    this.IDENTIFIER_STURES_NETWJOB = 'studentResourcesNetworkJob';
    this.IDENTIFIER_STURES_FOLLOWUP = 'studentResourcesFollowUp';
    this.IDENTIFIER_STURES_PRERECORD = 'studentResourcesPreRecord';
    this.IDENTIFIER_STURES_PHONEINTVW = 'studentResourcesPhoneInterview';
    this.IDENTIFIER_STURES_COMMONQNS = 'studentResourcesCommonQuestions';
    this.IDENTIFIER_STURES_CRRFAIR = 'studentResourcesCareerFair';
    this.IDENTIFIER_STURES_CRRPLANNING = 'studentResourcesCareerPlanning';
    this.IDENTIFIER_MANAGE_COLLEGE = 'manageCollege';
    this.IDENTIFIER_EMPLUSAGEREPORT = 'employerUsageReport';
    this.IDENTIFIER_CREDITSUSAGEREPORT = 'creditsUsageReport';
    this.IDENTIFIER_CONFIRMCLAIMCOLLEGE = 'confirmClaimCollege';
    this.IDENTIFIER_MAKEPAYMENT = 'makePayment';
    this.IDENTIFIER_PHONEPEPAYMENT = 'phonepePayment';
    this.IDENTIFIER_TRACKINVOICESREPORT = 'trackInvoicesReport';
    this.IDENTIFIER_COLLEGEMOU = 'collegeMOU';
    this.IDENTIFIER_RES_STUDENTASSMT = 'studentAssessmentResource';
    this.IDENTIFIER_COLLDGRDETAILS = 'collegeDegreeDetails';
    this.IDENTIFIER_DIRECTLOGIN = 'directLogin';
    this.IDENTIFIER_NOTNPREFERENCES = 'notificationPreferences';
    this.IDENTIFIER_APPLICANTCREDITS = 'applicantCredits';
    this.IDENTIFIER_EMPLOYABILITYSKILLS = 'employabilitySkills';
    this.IDENTIFIER_EMPLCOLLEGEEVENTS = 'employerCollegeEvents';
    this.IDENTIFIER_CAREERPATHWAY = 'careerPathway';
    this.IDENTIFIER_EMPLSUBSCRIPTION = 'employerSubscription';
    this.IDENTIFIER_VERIFYCERTIFICATE = 'verifyCertificate';
    this.IDENTIFIER_MOBILEDEVNOTN = 'mobileDeviceNotifications';
    this.IDENTIFIER_CLOSEACCOUNT = 'closeAccount';
    this.SUBIDENTIFIER_COMPETENCYASSMT_COMMUNICATION = 'communication';
    this.SUBIDENTIFIER_COMPETENCYASSMT_TEAMWORK = 'teamwork';
    this.SUBIDENTIFIER_COMPETENCYASSMT_LEADERSHIP = 'leadership';
    this.SUBIDENTIFIER_COMPETENCYASSMT_CREATIVITY = 'creativity';
    this.SUBIDENTIFIER_COMPETENCYASSMT_PROFESSIONALISM = 'professionalism';
    this.SUBIDENTIFIER_COMPETENCYASSMT_DIVERSITY = 'diversity';
    this.SUBIDENTIFIER_COMPETENCYASSMT_ADAPTABILITY = 'adaptability';
    this.SUBIDENTIFIER_COMPETENCYASSMT_DIGITAL = 'digital_competence';
    this.SUBIDENTIFIER_COMPETENCYASSMT_SCORE = 'readiness_score';
    // ui specific identifiers
    // 29-04-2022: retain same identifiers for backward compatibility
    this.USER_DETAILS_IDENTIFIER = 'risesharp_user_details';
    this.COLLEGE_DETAILS_IDENTIFIER = 'risesharp_college_details';
    this.EMPLOYER_DETAILS_IDENTIFIER = 'risesharp_employer_details';
    this.UPDATE_EMAIL_TRACKER = 'risesharp_ueu_tracker';
    this.TOKEN_CHANGE_LISTENER = 'token_change_listener';
    // student_purchases
    this.STUPUR_IDEN_EMPLSKILLS = 'EMPL_SKILLS';
    this.STUPUR_DESC_EMPLSKILLS = 'Employability Skills Course / Certificate';
  }
}
const ConstantsInstance = new Constants();
exports.Constants = ConstantsInstance;