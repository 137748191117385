import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Constants } from 'risesharp-common';
import { properties } from 'risesharp-ui';

import { General } from './../parentClasses/general';
import { Helper } from './../utils/helper';

const logo = require('./../images/gjr_black.png');

// required for observing menu changes
@inject('store')
@observer
class Footer extends General {
  constructor(props: React.Props<any>) {
    super(props);
    this.handleStudentTasks = this.handleStudentTasks.bind(this);
  }

  render() {
    return (
      <React.Fragment>
        {/*main footer*/}
        {this.displayMainFooter()}
      </React.Fragment>
    );
  }

  componentDidMount() {
    window.addEventListener('scroll', this.debounced(500, this.handleStudentTasks));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleStudentTasks);
  }

  // display sections
  private displayMainFooter() {
    const hideHeaderAndFooter = this.props.hideHeaderAndFooter || false;
    const isStudent = Helper.getAuthorizedUserType(this.props) === Constants.STUDENTS_USER_TYPE;
    const collegeObj = Helper.getCollegeObj(this.props);
    if (!hideHeaderAndFooter) {
      return (
        <div className="main-footer">
          <div className="container py-3">
            {/* social footer */}
            <div className="social-sec text-center">
              <h3>Stay Connected with us</h3>
              <ul>
                <li>
                  <a role="button" href="https://www.facebook.com/RiseSharp" rel="noopener" target="_blank">
                    <i className="fa fa-facebook" title="Facebook"/>
                  </a>
                </li>
                <li>
                  <a role="button" href="https://www.youtube.com/channel/UCEczExel4nDIEJRmlVzsUlA" rel="noopener" target="_blank">
                    <i className="fa fa-youtube" title="You Tube"/>
                  </a>
                </li>
                <li>
                  <a role="button" href="https://www.linkedin.com/company/GetJobReady" rel="noopener" target="_blank">
                    <i className="fa fa-linkedin" title="LinkedIn"/>
                  </a>
                </li>
              </ul>
            </div>
            {/* menu */}
            <div className="row mt-5 footer-links">
              {/* footer logo */}
              <div className="col-xs-12 col-sm-3 col-md-3 mgn-btm">
                {this.showFooterLogo()}
              </div>
              {/* menu col 1 */}
              <div className="col-xs-12 col-sm-3 col-md-3 mgn-btm">
                <h3 className="ft-head">Solutions</h3>
                <ul className="ft-ul">
                {this.displayUserMenuItems()} 
                </ul>
              </div>
              {/* menu col 2 */}
              <div className="col-xs-12 col-sm-3 col-md-3 mgn-btm">
                <h3 className="ft-head">Support</h3>
                <ul className="ft-ul">
                  <li className={(this.checkCurrentRoute('/contact-us') ? 'active' : '')}>
                      <a role="button" href="/contact-us" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/contact-us')}>Contact Us</a>
                  </li>
                  {(
                    isStudent &&
                    collegeObj !== null &&
                    <li className={(this.checkCurrentRoute('/contact-career-services') ? 'active' : '')}>
                        <a role="button" href="/contact-career-services" className="btn btn-link px-0" onClick={(e) => this.showPageWithState(e, '/contact-career-services', { college_code: collegeObj.college_code })}>Contact College</a>
                    </li>
                  )}
                  <li>
                    <a role="button" className="btn btn-link px-0" href="https://edainstitute.org/" rel="noopener" target="_blank">
                      Book
                    </a>
                  </li>
                </ul>
              </div>
              {/* menu col 3 */}
              <div className="col-xs-12 col-sm-3 col-md-3 mgn-btm">
                <h3 className="ft-head">Company</h3>
                <ul className="ft-ul">
                  <li className={(this.checkCurrentRoute('/about-us') ? 'active' : '')}>
                    <a role="button" href="/about-us" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/about-us')}>About Us</a>
                  </li> 
                  <li className={(this.checkCurrentRoute('/terms-of-service') ? 'active' : '')}>
                    <a role="button" href="/terms-of-service" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/terms-of-service')}>Terms Of Service</a>
                  </li> 
                  <li className={(this.checkCurrentRoute('/privacy-policy') ? 'active' : '')}>
                    <a role="button" href="/privacy-policy" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/privacy-policy')}>Privacy Policy</a>
                  </li>             
                </ul>
              </div>
            </div>
            {/* copyright */}
            <div className="row">
              <div className="col copyright">
                <p className="text-center">
                  Copyright &#169; {new Date().getFullYear()}. {properties.application.company}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (<div className="main-footer"></div>);
  }

  private showFooterLogo() {
    return (
      <a role="button" className="buttonAsLinkMenu" onClick={(e) => this.showPage(e, this.getHomeUrl())}>
        <img src={logo} className="img-fluid" alt=""/>
      </a>
    );
  }

  private displayDashboardLink() {
    return (
      <li className={(this.checkCurrentRoute(this.getHomeUrl()) ? 'active' : '')}>
        <a role="button" href={this.getHomeUrl()} className="btn btn-link px-0" onClick={(e) => this.showPage(e, this.getHomeUrl())}>
          Dashboard
        </a>
      </li>
    );
  }

  private displayUserMenuItems() {
    const isAnonymousUser = Helper.getAuthorizedUserId(this.props) === '';
    const isStaffUser = Helper.getAuthorizedUserType(this.props) === Constants.STAFF_USER_TYPE;
    const isEmployerUser = Helper.getAuthorizedUserType(this.props) === Constants.EMPLOYERS_USER_TYPE;
    const isStudent = Helper.getAuthorizedUserType(this.props) === Constants.STUDENTS_USER_TYPE;
    const isCollegeUser = Helper.getAuthorizedUserType(this.props) === Constants.COLLEGES_USER_TYPE;

    if (isAnonymousUser) {
      return (
        <React.Fragment>
          <li className={(this.checkCurrentRoute('/students') ? 'active' : '')}>
            <a role="button" href="/students" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/students')}>Students</a>
          </li>
          <li className={(this.checkCurrentRoute('/colleges') ? 'active' : '')}>
            <a role="button" href="/colleges" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/colleges')}>Colleges</a>
          </li>
          <li className={(this.checkCurrentRoute('/employers') ? 'active' : '')}>
            <a role="button" href="/employers" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/employers')}>Employers</a>
          </li>
        </React.Fragment>
      );
    }

    if (isStaffUser) {
      return (
        <React.Fragment>
          {this.displayDashboardLink()}
          <li className={(this.checkCurrentRoute('/staff/colleges-list') ? 'active' : '')}>
            <a role="button" href="/staff/colleges-list" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/staff/colleges-list')}>Colleges</a>
          </li>
          <li className={(this.checkCurrentRoute('/staff/employers-list') ? 'active' : '')}>
            <a role="button" href="/staff/employers-list" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/staff/employers-list')}>Employers</a>
          </li>
          <li className={(this.checkCurrentRoute('/approve-linked-jobs') ? 'active' : '')}>
            <a role="button" href="/approve-linked-jobs" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/approve-linked-jobs')}>Jobs</a>
          </li>
        </React.Fragment>
      );
    }

    if (isEmployerUser) {
      return (
        <React.Fragment>
          {this.displayDashboardLink()}
          {(
            this.showEmployerMenuItem('colleges') &&
            <li className={(this.checkCurrentRoute('/employers/colleges-list') ? 'active' : '')}>
              <a role="button" href="/employers/colleges-list" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/employers/colleges-list')}>Colleges</a>
            </li>
          )}
          {(
            this.showEmployerMenuItem('jobs') &&
            <li className={(this.checkCurrentRoute('/employers/jobs-list') ? 'active' : '')}>
              <a role="button" href="/employers/jobs-list" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/employers/jobs-list')}>Jobs</a>
            </li>
          )}
        </React.Fragment>
      );
    }

    if (isStudent) {
      return (
        <React.Fragment>
          {this.displayDashboardLink()}
          <li className={(this.checkCurrentRoute('/students/career-resources') ? 'active' : '')}>
            <a role="button" href="/students/career-resources" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/students/career-resources')}>
              Resources
            </a>
          </li>
          <li className={(this.checkCurrentRoute('/students/jobs-list') ? 'active' : '')}>
            <a role="button" href="/students/jobs-list" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/students/jobs-list')}>
              Jobs
            </a>
          </li>
        </React.Fragment>
      );
    }

    if (isCollegeUser) {
      return (
        <React.Fragment>
          {this.displayDashboardLink()}
          {(
            this.showCollegeMenuItem('students') &&
            <li className={(this.checkCurrentRoute('/colleges/students') ? 'active' : '')}>
              <a role="button" href="/colleges/students" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/colleges/students')}>
                Students
              </a>
            </li>
          )}
          {(
            this.showCollegeMenuItem('jobs') &&
            <li className={(this.checkCurrentRoute('/approve-linked-jobs') ? 'active' : '')}>
              <a role="button" href="/approve-linked-jobs" className="btn btn-link px-0" onClick={(e) => this.showPage(e, '/approve-linked-jobs')}>
                Jobs
              </a>
            </li>
          )}
        </React.Fragment>
      );
    }

    return null;
  }

  private handleStudentTasks() {
    if (Helper.getAuthorizedUserId(this.props) &&
    Helper.checkActiveUserType(this.props, Constants.STUDENTS_USER_TYPE)) {
      Helper.checkStudentTasks(this.props, window, 'scroll');
    }
  }

  // https://codeburst.io/throttling-and-debouncing-in-javascript-646d076d0a44
  private debounced(delay: number, fn: Function) {
    let timerId: any;
    return function (...args: any[]) {
      if (timerId) {
        clearTimeout(timerId);
      }
      // tslint:disable:align
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  }
}

export default Footer;
