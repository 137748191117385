"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Base = void 0;
// @ts-ignore
const get = require("lodash.get");
const restClient_1 = require("./../utils/restClient");
const apiMessageHelper_1 = require("./../utils/apiMessageHelper");
const multiSelect_1 = require("./../utils/multiSelect");
class Base {
  getServiceInputDetails(serviceInput) {
    const token = serviceInput.token;
    const isMobileApp = serviceInput.apiArgs.isMobileApp;
    const input = serviceInput.apiArgs.input;
    return [token, isMobileApp, input];
  }
  handleGetOpsInput(token, input, url) {
    let identifier = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return __awaiter(this, void 0, void 0, function* () {
      let finalUrl = url;
      if (identifier === 'id') {
        finalUrl = this.getIdUrl(url, input);
      } else if (identifier === 'slug') {
        finalUrl = this.getSlugUrl(url, input);
      } else if (identifier === 'check-slug') {
        finalUrl = this.getCheckSlugUrl(url, input);
      } else if (identifier === 's3-signed-url') {
        finalUrl = this.getS3Url(url, input);
      } else if (identifier === 'fetch-administrators') {
        finalUrl = this.getAdminUrl(url, input);
      } else if (identifier === 'employer-link-colleges') {
        finalUrl = this.getLinkCollegeUrl(url, input);
      } else if (identifier === 'employer-link-jobs') {
        finalUrl = this.getLinkJobUrl(url, input);
      } else if (identifier === 'sns-count') {
        finalUrl = this.getStaffNotifyStudents(url, input);
      } else if (identifier === 'assessments-list') {
        finalUrl = this.getStudentsAssessmentsList(url, input);
      } else if (identifier === 'college-courses') {
        finalUrl = this.getCollegeCourses(url, input);
      } else if (identifier === 'track-access-users') {
        finalUrl = this.getTrackAccessUsers(url, input);
      } else if (identifier === 'view-resume') {
        finalUrl = this.getViewResume(url, input);
      } else if (identifier === 'cct-get') {
        finalUrl = this.getCollegeCareerTimelines(url, input);
      } else if (identifier === 'cms-get') {
        finalUrl = this.getCollegeManageStaff(url, input);
      } else if (identifier === 'ems-get') {
        finalUrl = this.getEmployerManageStaff(url, input);
      } else if (identifier === 'sct-get') {
        finalUrl = this.getStudentCareerTimeline(url, input);
      } else if (identifier === 'mn-get') {
        finalUrl = this.getMentoringNotes(url, input);
      } else if (identifier === 'job-search') {
        finalUrl = this.getJobSearchStatusUrl(url, input);
      } else if (identifier === 'job-placement-summary') {
        finalUrl = this.getJobPlacementSummaryUrl(url, input);
      } else if (identifier === 'evws-get') {
        finalUrl = this.getEventsWorkshops(url, input);
      } else if (identifier === 'employer-candidate-summary') {
        finalUrl = this.getEmployerCandidateSummaryUrl(url, input);
      } else if (identifier === 'job-details') {
        finalUrl = this.getJobDetailsUrl(url, input);
      } else if (identifier === 'apply-job') {
        finalUrl = this.getApplyJobUrl(url, input);
      } else if (identifier === 'staff-notify-tpos') {
        finalUrl = this.getNotifyTpoUrl(url, input);
      } else if (identifier === 'smu-get') {
        finalUrl = this.getStaffManageUsersUrl(url, input);
      } else if (identifier === 'employer-usage-report') {
        finalUrl = this.getEmployerUsageReportUrl(url, input);
      } else if (identifier === 'credits-usage-report') {
        finalUrl = this.getCreditsUsageReportUrl(url, input);
      } else if (identifier === 'approve-linked-jobs-main') {
        finalUrl = this.getALJMainUrl(url, input);
      } else if (identifier === 'approve-linked-jobs-resume') {
        finalUrl = this.getALJResumeUrl(url, input);
      } else if (identifier === 'view-candidates') {
        finalUrl = this.getViewCandidatesUrl(url, input);
      } else if (identifier === 'make-payment') {
        finalUrl = this.getMakePaymentUrl(url, input);
      } else if (identifier === 'upi-payment') {
        finalUrl = this.getUpiPaymentUrl(url, input);
      } else if (identifier === 'track-invoices-report') {
        finalUrl = this.getTrackInvoicesReportUrl(url, input);
      } else if (identifier === 'college-degree-details') {
        finalUrl = this.getCollegeDgrListUrl(url, input);
      } else if (identifier === 'direct-login') {
        finalUrl = this.getDirectLoginUrl(url, input);
      } else if (identifier === 'url-shortener') {
        finalUrl = this.getShortUrl(url, input);
      } else if (identifier === 'ac-get') {
        finalUrl = this.getACUrl(url, input);
      } else if (identifier === 'jau-get') {
        finalUrl = this.getJobUrl(url, input);
      } else if (identifier === 'emsk-get') {
        finalUrl = this.getEmployabilitySkillsUrl(url, input);
      } else if (identifier === 'ece-get') {
        finalUrl = this.getEmployerCollegeEventsUrl(url, input);
      } else if (identifier === 'misc-details') {
        finalUrl = this.getMiscDetailsUrl(url, input);
      } else if (identifier === 'empl-skills-payment') {
        finalUrl = this.getEmplSkillsPaymentUrl(url, input);
      } else if (identifier === 'mb-proxy') {
        finalUrl = this.getMbProxyUrl(url, input);
      } else if (identifier === 'filters') {
        finalUrl = this.getFiltersUrl(url, input);
      } else if (identifier === 'pagination') {
        finalUrl = this.getPaginationUrl(url, input);
      }
      return this.handleGet(token, finalUrl);
    });
  }
  handlePostOpsInput(token, input, url) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.handlePost(token, input, url);
    });
  }
  handlePutOpsInput(token, input, url) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.handlePut(token, input, url);
    });
  }
  handleDeleteOpsInput(token, input, url) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.handleDelete(token, input, url);
    });
  }
  setErrorOutput(err, reply) {
    let check401 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    if (!check401) {
      reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getDefaultError();
    } else {
      if (err.response && err.response.status === 401) {
        reply.is401Error = true;
      } else {
        reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getDefaultError();
      }
    }
  }
  cleanupMessage(input, key) {
    let isMobileApp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    const output = Object.assign({}, input);
    const messageStr = 'message';
    const message = get(input, key + '.' + messageStr);
    if (message !== undefined) {
      output[key][messageStr] = apiMessageHelper_1.ApiMessageHelper.getMessage(message, isMobileApp);
    }
    return output;
  }
  handleGet(token, url) {
    return __awaiter(this, void 0, void 0, function* () {
      const instance = restClient_1.RestClient.getInstance(token);
      const response = yield instance.get(url);
      const responseJson = response.data;
      return responseJson;
    });
  }
  handlePost(token, input, url) {
    return __awaiter(this, void 0, void 0, function* () {
      const instance = restClient_1.RestClient.getInstance(token);
      const response = yield instance.post(url, input);
      const responseJson = response.data;
      return responseJson;
    });
  }
  handlePut(token, input, url) {
    return __awaiter(this, void 0, void 0, function* () {
      const instance = restClient_1.RestClient.getInstance(token);
      const response = yield instance.put(url, input);
      const responseJson = response.data;
      return responseJson;
    });
  }
  handleDelete(token, data, url) {
    return __awaiter(this, void 0, void 0, function* () {
      const instance = restClient_1.RestClient.getInstance(token);
      const config = {
        data
      };
      const response = yield instance.delete(url, config);
      const responseJson = response.data;
      return responseJson;
    });
  }
  getIdUrl(url, input) {
    return url + '/' + input.id;
  }
  getSlugUrl(url, input) {
    return url + '?is_slug=Y&slug=' + encodeURIComponent(input.slug);
  }
  getCheckSlugUrl(url, input) {
    return url + '?slug=' + encodeURIComponent(input.slug) + '&user_type=' + encodeURIComponent(input.user_type);
  }
  getS3Url(url, input) {
    let str = '';
    if (input.filename) {
      str += '&filename=' + encodeURIComponent(input.filename);
    }
    if (input.type) {
      str += '&type=' + encodeURIComponent(input.type);
    }
    if (input.folder_type) {
      str += '&folder_type=' + encodeURIComponent(input.folder_type);
    }
    if (input.id) {
      str += '&id=' + encodeURIComponent(input.id);
    }
    if (input.student_id) {
      str += '&student_id=' + encodeURIComponent(input.student_id);
    }
    if (input.action_type) {
      str += '&action_type=' + encodeURIComponent(input.action_type);
    }
    if (input.display_url) {
      str += '&display_url=' + encodeURIComponent(input.display_url);
    }
    if (str.length > 0) {
      const tmpStr = str.slice(1);
      if (url.indexOf('?') === -1) {
        return url + '?' + tmpStr;
      }
      return url + '&' + tmpStr;
    }
    return url;
  }
  getAdminUrl(url, input) {
    return url + '?type=' + encodeURIComponent(input.type) + '&query=' + encodeURIComponent(input.query);
  }
  getLinkCollegeUrl(url, input) {
    let tmpUrl = url + '?action_type=' + encodeURIComponent(input.action_type) + '&job_id=' + encodeURIComponent(input.job_id) + '&query=' + encodeURIComponent(input.query) + '&state=' + encodeURIComponent(input.state) + '&country=' + encodeURIComponent(input.country) + '&verified_colleges_only=' + encodeURIComponent(input.verified_colleges_only);
    if (input.staff_masq_employer_id) {
      tmpUrl += '&staff_masq_employer_id=' + encodeURIComponent(input.staff_masq_employer_id);
    }
    return tmpUrl;
  }
  getLinkJobUrl(url, input) {
    const newUrl = url + '?id=' + encodeURIComponent(input.id);
    return this.getPaginationUrl(newUrl, input);
  }
  getStaffNotifyStudents(url, input) {
    let newUrl = url + '?institution_type=' + encodeURIComponent(input.institution_type) + '&state=' + encodeURIComponent(input.state) + '&student_type=' + encodeURIComponent(input.student_type) + '&message_mode=' + encodeURIComponent(input.message_mode);
    newUrl += '&degree_program=' + encodeURIComponent(input.degree_program) + '&degree_code=' + encodeURIComponent(input.degree_code) + '&degree_duration=' + encodeURIComponent(input.degree_duration) + '&degree_specialization=' + encodeURIComponent(input.degree_specialization) + '&admission_year=' + encodeURIComponent(input.admission_year) + '&graduation_year=' + encodeURIComponent(input.graduation_year);
    return newUrl;
  }
  getStudentsAssessmentsList(url, input) {
    return url + '?student_id=' + encodeURIComponent(input.student_id);
  }
  getCollegeCourses(url, input) {
    if (input.id) {
      return url + '/' + input.id;
    }
    return url;
  }
  getTrackAccessUsers(url, input) {
    const actionType = input.action_type || '';
    const collegeId = input.college_id || '';
    const employerId = input.employer_id || '';
    const jobId = input.job_id || '';
    const newUrl = url + '?action_type=' + actionType + '&college_id=' + collegeId + '&employer_id=' + employerId + '&job_id=' + jobId;
    return this.getPaginationUrl(newUrl, input);
  }
  getViewResume(url, input) {
    let finalUrl = url;
    if (input.slug) {
      finalUrl += '/' + input.slug;
    }
    if (input.jid) {
      finalUrl += '/' + input.jid;
    }
    return finalUrl;
  }
  getCollegeCareerTimelines(url, input) {
    let finalUrl = url;
    const actionType = input.action_type || '';
    const tmpStr = '?action_type=' + encodeURIComponent(actionType);
    if (actionType === 'main_list') {
      finalUrl += tmpStr;
      finalUrl = this.getPaginationUrl(finalUrl, input);
    } else if (actionType === 'program_level_list') {
      finalUrl += tmpStr;
      finalUrl += '&timeline_id=' + (input.timeline_id || '');
      finalUrl = this.getPaginationUrl(finalUrl, input);
    } else if (actionType === 'term_level_list') {
      finalUrl += tmpStr;
      finalUrl += '&term_id=' + (input.term_id || '');
      finalUrl = this.getPaginationUrl(finalUrl, input);
    } else if (actionType === 'terms_display_order' || actionType === 'tasks_display_order') {
      finalUrl += tmpStr;
      finalUrl += '&id=' + (input.id || '');
      finalUrl = this.getPaginationUrl(finalUrl, input);
    }
    return finalUrl;
  }
  getCollegeManageStaff(url, input) {
    let finalUrl = url;
    const actionType = input.action_type || '';
    const tmpStr = '?action_type=' + encodeURIComponent(actionType);
    if (actionType === 'main_list') {
      finalUrl += tmpStr;
      finalUrl = this.getPaginationUrl(finalUrl, input);
    }
    if (actionType === 'account_profile_list') {
      finalUrl += tmpStr;
      finalUrl += '&account_profile_id=' + encodeURIComponent(input.account_profile_id);
    }
    return finalUrl;
  }
  getEmployerManageStaff(url, input) {
    let finalUrl = url;
    const actionType = input.action_type || '';
    const tmpStr = '?action_type=' + encodeURIComponent(actionType);
    if (actionType === 'main_list') {
      finalUrl += tmpStr;
      finalUrl = this.getPaginationUrl(finalUrl, input);
    }
    return finalUrl;
  }
  getStudentCareerTimeline(url, input) {
    let finalUrl = url;
    if (input.student_id) {
      finalUrl += '/' + input.student_id;
    }
    return finalUrl;
  }
  getMentoringNotes(url, input) {
    let finalUrl = url;
    const actionType = input.action_type || '';
    const uid = input.uid || '';
    const tmpStr1 = '?action_type=' + encodeURIComponent(actionType);
    const tmpStr2 = '&uid=' + encodeURIComponent(uid);
    finalUrl += tmpStr1 + tmpStr2;
    return finalUrl;
  }
  getJobSearchStatusUrl(url, input) {
    if (input.student_id) {
      return url + '/' + input.student_id;
    }
    return url;
  }
  getJobPlacementSummaryUrl(url, input) {
    return url + '?graduation_year=' + encodeURIComponent(input.graduation_year);
  }
  getEventsWorkshops(url, input) {
    let finalUrl = url;
    const actionType = input.action_type || '';
    const tmpStr = '?action_type=' + encodeURIComponent(actionType);
    if (actionType === 'main_list') {
      finalUrl += tmpStr;
      finalUrl = this.getPaginationUrl(finalUrl, input);
    }
    if (actionType === 'event_details') {
      finalUrl += tmpStr;
      const id = input.id || '';
      finalUrl += '&id=' + encodeURIComponent(id);
    }
    return finalUrl;
  }
  getEmployerCandidateSummaryUrl(url, input) {
    const actionType = input.action_type || 'main_list_coll_name';
    const jobId = input.job_id || '';
    const jobStatus = input.job_status || '';
    const queryString = input.search_query || '';
    const degreeProgram = input.degree_program || '';
    const degreeSpecialization = input.degree_specialization || '';
    const searchDisplayType = input.search_display_type || '';
    return url + '?action_type=' + encodeURIComponent(actionType) + '&job_id=' + encodeURIComponent(jobId) + '&job_status=' + encodeURIComponent(jobStatus) + '&search_query=' + encodeURIComponent(queryString) + '&degree_program=' + encodeURIComponent(degreeProgram) + '&degree_specialization=' + encodeURIComponent(degreeSpecialization) + '&search_display_type=' + encodeURIComponent(searchDisplayType);
  }
  getJobDetailsUrl(url, input) {
    let newUrl = url + '/' + input.id;
    if (input.linked_college_id) {
      newUrl += '/' + input.linked_college_id;
    }
    return newUrl;
  }
  getApplyJobUrl(url, input) {
    let newUrl = url + '/' + input.employer_job_id;
    if (input.linked_college_id) {
      newUrl += '/' + input.linked_college_id;
    }
    return newUrl;
  }
  getNotifyTpoUrl(url, input) {
    return url + '?role_identifier=' + encodeURIComponent(input.role_identifier) + '&payment_plan=' + encodeURIComponent(input.payment_plan) + '&country=' + encodeURIComponent(input.country) + '&state=' + encodeURIComponent(input.state) + '&college_type=' + encodeURIComponent(input.college_type);
  }
  getStaffManageUsersUrl(url, input) {
    let finalUrl = url;
    const actionType = input.action_type || '';
    const tmpStr = '?action_type=' + encodeURIComponent(actionType);
    if (actionType === 'main_list') {
      finalUrl += tmpStr;
      finalUrl = this.getPaginationUrl(finalUrl, input);
    }
    return finalUrl;
  }
  getEmployerUsageReportUrl(url, input) {
    const start_date = input.start_date || '';
    const end_date = input.end_date || '';
    return url + '?start_date=' + encodeURIComponent(start_date) + '&end_date=' + encodeURIComponent(end_date);
  }
  getCreditsUsageReportUrl(url, input) {
    const report_date = input.report_date || '';
    const communication_identifier = input.communication_identifier || '';
    return url + '?report_date=' + encodeURIComponent(report_date) + '&communication_identifier=' + encodeURIComponent(communication_identifier);
  }
  getALJMainUrl(url, input) {
    const action_type = input.action_type || '';
    const newUrl = url + '?action_type=' + encodeURIComponent(action_type);
    return this.getPaginationUrl(newUrl, input);
  }
  getALJResumeUrl(url, input) {
    const action_type = input.action_type || '';
    const job_id = input.resume_job_id || '';
    return url + '?job_id=' + encodeURIComponent(job_id) + '&action_type=' + encodeURIComponent(action_type);
  }
  getViewCandidatesUrl(url, input) {
    const newUrl = url + '?search_type=' + encodeURIComponent(input.search_type) + '&search_query=' + encodeURIComponent(input.search_query);
    return this.getPaginationUrl(newUrl, input);
  }
  getMakePaymentUrl(url, input) {
    let finalUrl = url + '?action_type=' + encodeURIComponent(input.action_type);
    if (input.course_id) {
      finalUrl += '&course_id=' + encodeURIComponent(input.course_id);
    }
    if (input.pmt_reqd) {
      finalUrl += '&pmt_reqd=' + encodeURIComponent(input.pmt_reqd);
    }
    if (input.pmt_reqd_sig) {
      finalUrl += '&pmt_reqd_sig=' + encodeURIComponent(input.pmt_reqd_sig);
    }
    if (input.discount_code) {
      finalUrl += '&discount_code=' + encodeURIComponent(input.discount_code);
    }
    return finalUrl;
  }
  getUpiPaymentUrl(url, input) {
    let finalUrl = url + '?action_type=' + encodeURIComponent(input.action_type);
    if (input.course_id) {
      finalUrl += '&course_id=' + encodeURIComponent(input.course_id);
    }
    if (input.pmt_reqd) {
      finalUrl += '&pmt_reqd=' + encodeURIComponent(input.pmt_reqd);
    }
    if (input.pmt_reqd_sig) {
      finalUrl += '&pmt_reqd_sig=' + encodeURIComponent(input.pmt_reqd_sig);
    }
    if (input.status_id) {
      finalUrl += '&status_id=' + encodeURIComponent(input.status_id);
    }
    if (input.next) {
      finalUrl += '&next=' + encodeURIComponent(input.next);
    }
    return finalUrl;
  }
  getTrackInvoicesReportUrl(url, input) {
    const report_year = input.report_year || '';
    const report_month = input.report_month || '';
    return url + '?report_year=' + encodeURIComponent(report_year) + '&report_month=' + encodeURIComponent(report_month);
  }
  getCollegeDgrListUrl(url, input) {
    const actionType = input.action_type || '';
    const selectedProgram = input.selected_program || '';
    const newUrl = url + '?action_type=' + actionType + '&sel_dp=' + encodeURIComponent(selectedProgram);
    return this.getPaginationUrl(newUrl, input);
  }
  getDirectLoginUrl(url, input) {
    const code = input.code || '';
    const newUrl = url + '?code=' + encodeURIComponent(code);
    return newUrl;
  }
  getShortUrl(url, input) {
    return url + '/' + input.slug;
  }
  getACUrl(url, input) {
    const employerId = input.employer_id || '';
    const newUrl = url + '?employer_id=' + encodeURIComponent(employerId);
    return this.getPaginationUrl(newUrl, input);
  }
  getJobUrl(url, input) {
    let newUrl = url;
    if (input.id) {
      newUrl += '/' + input.id;
    }
    const actionType = input.action_type || '';
    newUrl += '?action_type=' + encodeURIComponent(actionType);
    return this.getPaginationUrl(newUrl, input);
  }
  getEmployabilitySkillsUrl(url, input) {
    let newUrl = url;
    const actionType = input.action_type || '';
    newUrl += '?action_type=' + encodeURIComponent(actionType);
    if (input.skill_id) {
      newUrl += '&skill_id=' + encodeURIComponent(input.skill_id);
    }
    if (input.module_id) {
      newUrl += '&module_id=' + encodeURIComponent(input.module_id);
    }
    if (input.topic_id) {
      newUrl += '&topic_id=' + encodeURIComponent(input.topic_id);
    }
    return this.getPaginationUrl(newUrl, input);
  }
  getEmployerCollegeEventsUrl(url, input) {
    let finalUrl = url;
    const actionType = input.action_type || '';
    const tmpStr = '?action_type=' + encodeURIComponent(actionType);
    if (actionType === 'college_list') {
      const collegeName = input.college_name || '';
      const state = input.state || '';
      finalUrl += tmpStr;
      finalUrl += '&college_name=' + encodeURIComponent(collegeName) + '&state=' + encodeURIComponent(state);
    }
    if (actionType === 'main_list') {
      const state = input.state || '';
      const collegeId = input.college_id || '';
      finalUrl += tmpStr;
      finalUrl = this.getPaginationUrl(finalUrl, input);
      finalUrl += '&state=' + encodeURIComponent(state);
      finalUrl += '&college_id=' + encodeURIComponent(collegeId);
    }
    return finalUrl;
  }
  getMiscDetailsUrl(url, input) {
    let str = url;
    const actionType = input.action_type || '';
    str += '?action_type=' + encodeURIComponent(actionType);
    if (input.signup_discount_code) {
      str += '&signup_discount_code=' + encodeURIComponent(input.signup_discount_code);
    }
    return str;
  }
  getEmplSkillsPaymentUrl(url, input) {
    let finalUrl = url + '?action_type=' + encodeURIComponent(input.action_type);
    if (input.course_id) {
      finalUrl += '&course_id=' + encodeURIComponent(input.course_id);
    }
    if (input.pmt_reqd) {
      finalUrl += '&pmt_reqd=' + encodeURIComponent(input.pmt_reqd);
    }
    if (input.pmt_reqd_sig) {
      finalUrl += '&pmt_reqd_sig=' + encodeURIComponent(input.pmt_reqd_sig);
    }
    if (input.email_or_mobile) {
      finalUrl += '&email_or_mobile=' + encodeURIComponent(input.email_or_mobile);
    }
    if (input.status_id) {
      finalUrl += '&status_id=' + encodeURIComponent(input.status_id);
    }
    if (input.next) {
      finalUrl += '&next=' + encodeURIComponent(input.next);
    }
    return finalUrl;
  }
  getMbProxyUrl(url, input) {
    const token = input.token || '';
    const actionType = input.action_type || '';
    const matchUid = input.match_uid || '';
    const newUrl = url + '?token=' + encodeURIComponent(token) + '&action_type=' + encodeURIComponent(actionType) + '&match_uid=' + encodeURIComponent(matchUid);
    return newUrl;
  }
  getFiltersUrl(url, input) {
    let str = url + '?action_type=' + encodeURIComponent(input.action_type);
    if (input.degree_program) {
      str += '&degree_program=' + encodeURIComponent(input.degree_program);
    }
    if (input.degree_specialization) {
      str += '&degree_specialization=' + encodeURIComponent(input.degree_specialization);
    }
    if (input.query) {
      str += '&query=' + encodeURIComponent(input.query);
    }
    return str;
  }
  getPaginationUrl(url, input) {
    let str = "";
    if (input.p) {
      str += '&p=' + encodeURIComponent(input.p);
    }
    if (input.q) {
      str += '&q=' + encodeURIComponent(input.q);
    }
    if (input.r) {
      str += '&r=' + encodeURIComponent(input.r);
    }
    if (input.s) {
      str += '&s=' + encodeURIComponent(input.s);
    }
    if (input.t) {
      str += '&t=' + encodeURIComponent(input.t);
    }
    if (input.jt) {
      str += '&jt=' + encodeURIComponent(input.jt);
    }
    if (input.jf) {
      str += '&jf=' + encodeURIComponent(input.jf);
    }
    const dpMultiSelect = multiSelect_1.MultiSelect.getValue(input.dp);
    if (Array.isArray(dpMultiSelect) && dpMultiSelect.length > 0) {
      for (const x of dpMultiSelect) {
        str += '&dp[]=' + encodeURIComponent(x);
      }
    } else if (typeof dpMultiSelect === 'string' && dpMultiSelect) {
      str += '&dp=' + encodeURIComponent(dpMultiSelect);
    }
    const dsMultiSelect = multiSelect_1.MultiSelect.getValue(input.ds);
    if (Array.isArray(dsMultiSelect) && dsMultiSelect.length > 0) {
      for (const x of dsMultiSelect) {
        str += '&ds[]=' + encodeURIComponent(x);
      }
    } else if (typeof dsMultiSelect === 'string' && dsMultiSelect) {
      str += '&ds=' + encodeURIComponent(dsMultiSelect);
    }
    if (input.gy) {
      str += '&gy=' + encodeURIComponent(input.gy);
    }
    if (input.ity) {
      str += '&ity=' + encodeURIComponent(input.ity);
    }
    const ssMultiSelect = multiSelect_1.MultiSelect.getValue(input.ss);
    if (Array.isArray(ssMultiSelect) && ssMultiSelect.length > 0) {
      for (const x of ssMultiSelect) {
        str += '&ss[]=' + encodeURIComponent(x);
      }
    } else if (typeof ssMultiSelect === 'string' && ssMultiSelect) {
      str += '&ss=' + encodeURIComponent(ssMultiSelect);
    }
    if (input.tpr) {
      str += '&tpr=' + encodeURIComponent(input.tpr);
    }
    if (input.emp) {
      str += '&emp=' + encodeURIComponent(input.emp);
    }
    const jrdMultiSelect = multiSelect_1.MultiSelect.getValue(input.jrd);
    if (Array.isArray(jrdMultiSelect) && jrdMultiSelect.length > 0) {
      for (const x of jrdMultiSelect) {
        str += '&jrd[]=' + encodeURIComponent(x);
      }
    } else if (typeof jrdMultiSelect === 'string' && jrdMultiSelect) {
      str += '&jrd=' + encodeURIComponent(jrdMultiSelect);
    }
    if (input.llt) {
      str += '&llt=' + encodeURIComponent(input.llt);
    }
    if (input.cle) {
      str += '&cle=' + encodeURIComponent(input.cle);
    }
    if (input.mnt) {
      str += '&mnt=' + encodeURIComponent(input.mnt);
    }
    if (input.ev_ty) {
      str += '&ev_ty=' + encodeURIComponent(input.ev_ty);
    }
    if (input.ev_ta) {
      str += '&ev_ta=' + encodeURIComponent(input.ev_ta);
    }
    const eskMultiSelect = multiSelect_1.MultiSelect.getValue(input.esk);
    if (Array.isArray(eskMultiSelect) && eskMultiSelect.length > 0) {
      for (const x of eskMultiSelect) {
        str += '&esk[]=' + encodeURIComponent(x);
      }
    } else if (typeof eskMultiSelect === 'string' && eskMultiSelect) {
      str += '&esk=' + encodeURIComponent(eskMultiSelect);
    }
    const stMultiSelect = multiSelect_1.MultiSelect.getValue(input.st);
    if (Array.isArray(stMultiSelect) && stMultiSelect.length > 0) {
      for (const x of stMultiSelect) {
        str += '&st[]=' + encodeURIComponent(x);
      }
    } else if (typeof stMultiSelect === 'string' && stMultiSelect) {
      str += '&st=' + encodeURIComponent(stMultiSelect);
    }
    if (input.pp) {
      str += '&pp=' + encodeURIComponent(input.pp);
    }
    if (input.ccs) {
      str += '&ccs=' + encodeURIComponent(input.ccs);
    }
    if (input.emp_rec) {
      str += '&emp_rec=' + encodeURIComponent(input.emp_rec);
    }
    if (input.cum_gpa) {
      str += '&cum_gpa=' + encodeURIComponent(input.cum_gpa);
    }
    if (input.cum_pr) {
      str += '&cum_pr=' + encodeURIComponent(input.cum_pr);
    }
    const stJctMultiSelect = multiSelect_1.MultiSelect.getValue(input.st_jct);
    if (Array.isArray(stJctMultiSelect) && stJctMultiSelect.length === 2) {
      str += '&st_jct=' + encodeURIComponent(stJctMultiSelect.join('____'));
    }
    const stSctMultiSelect = multiSelect_1.MultiSelect.getValue(input.st_sct);
    if (Array.isArray(stSctMultiSelect) && stSctMultiSelect.length === 2) {
      str += '&st_sct=' + encodeURIComponent(stSctMultiSelect.join('____'));
    }
    const stCctMultiSelect = multiSelect_1.MultiSelect.getValue(input.st_cct);
    if (Array.isArray(stCctMultiSelect) && stCctMultiSelect.length === 2) {
      str += '&st_cct=' + encodeURIComponent(stCctMultiSelect.join('____'));
    }
    const stFtjMultiSelect = multiSelect_1.MultiSelect.getValue(input.st_ftj);
    if (Array.isArray(stFtjMultiSelect) && stFtjMultiSelect.length === 2) {
      str += '&st_ftj=' + encodeURIComponent(stFtjMultiSelect.join('____'));
    }
    const stFteMultiSelect = multiSelect_1.MultiSelect.getValue(input.st_fte);
    if (Array.isArray(stFteMultiSelect) && stFteMultiSelect.length === 2) {
      str += '&st_fte=' + encodeURIComponent(stFteMultiSelect.join('____'));
    }
    const stFtcMultiSelect = multiSelect_1.MultiSelect.getValue(input.st_ftc);
    if (Array.isArray(stFtcMultiSelect) && stFtcMultiSelect.length === 2) {
      str += '&st_ftc=' + encodeURIComponent(stFtcMultiSelect.join('____'));
    }
    if (input.gnd) {
      str += '&gnd=' + encodeURIComponent(input.gnd);
    }
    const spadMultiSelect = multiSelect_1.MultiSelect.getValue(input.sp_ab_di);
    if (Array.isArray(spadMultiSelect) && spadMultiSelect.length > 0) {
      for (const x of spadMultiSelect) {
        str += '&sp_ab_di[]=' + encodeURIComponent(x);
      }
    } else if (typeof spadMultiSelect === 'string' && spadMultiSelect) {
      str += '&sp_ab_di=' + encodeURIComponent(spadMultiSelect);
    }
    if (input.cty) {
      str += '&cty=' + encodeURIComponent(input.cty);
    }
    if (input.cti) {
      str += '&cti=' + encodeURIComponent(input.cti);
    }
    if (input.nlps) {
      str += '&nlps=' + encodeURIComponent(input.nlps);
    }
    if (str.length > 0) {
      const tmpStr = str.slice(1);
      if (url.indexOf('?') === -1) {
        return url + '?' + tmpStr;
      }
      return url + '&' + tmpStr;
    }
    return url;
  }
}
exports.Base = Base;